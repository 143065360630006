import { type ApolloError } from '@apollo/client/errors'
import { Alert } from '../../shared/alert/Alert'
import { type FC } from 'react'
import * as styles from './companyForm.module.css'

export const ErrorAlert: FC<{ errorMessage?: ApolloError['message'] }> = ({
  errorMessage
}) => {
  if (!errorMessage) return null

  return (
    <div className={styles.alert}>
      <Alert type='error' id='add-bh-company'>
        {errorMessage}
      </Alert>
    </div>
  )
}
