import type { FC, ReactNode } from 'react'
import * as sharedStyles from '../../../../styles.module.css'
import { Button, type ButtonProps } from '../../../shared/button/Button'
import { Icon, type IconProps } from '../../../shared/icons/Icons'
import { Text } from '../../../shared/typography'
import * as styles from './contact-flyout.module.css'
import { type IconOptions } from '~/src/components/shared/icons/symbols'

interface ContactFlyoutListItemProps {
  buttonText: string
  iconSymbol: IconOptions
  iconProps?: Omit<Partial<IconProps>, 'symbol'>
  buttonProps?: Omit<Partial<ButtonProps>, 'children'>
  afterButton?: ReactNode
  onClick?: () => void
  isDisabled?: boolean
}

export const ContactFlyoutListItem: FC<ContactFlyoutListItemProps> = (
  props
) => {
  const {
    onClick,
    buttonProps,
    iconSymbol,
    iconProps,
    buttonText,
    afterButton,
    isDisabled = false
  } = props

  return (
    <li className={sharedStyles['flyout-item']}>
      <Button
        as='button'
        style='icon-only'
        onClick={onClick}
        {...buttonProps}
        disabled={isDisabled}
        className={styles['btn-full-width']}
      >
        <Icon
          space='right'
          color={isDisabled ? 'disabled-check' : 'alku-red'}
          size='md'
          symbol={iconSymbol}
          {...iconProps}
        />
        <Text
          as='span'
          style='detail'
          color={isDisabled ? 'disabled-check' : 'dark-blue'}
          weight='semibold'
        >
          {buttonText}
        </Text>
      </Button>
      {afterButton}
    </li>
  )
}
