import { useCallback, useEffect, useMemo, useState } from 'react'

import { SelectDropdown } from '../../shared/select/SelectDropdown'
import { Favorite } from '../../shared/favorite/Favorite'
import {
  useUpdateActiveListSortPreferenceMutation,
  type CallList,
  type SortType,
  ListType
} from '../../../__generated__/gql-types'

/* Filter Data */
import { activeListSortData } from '~/src/screens/listScreens/constants/sortDropdownData'
import { contactExpandedFilterData } from '~/src/screens/listScreens/constants/contactExpandedFilterData'
import { FlyoutListItem } from '../../shared/flyout/FlyoutListItem'
import { CallListHeadingSummary } from '../CallListHeading/CallListHeadingSummary'
import { FlyoutIconButton } from '../FlyoutIconButton/FlyoutIconButton'
import { type AccordionTypes } from '../company/CompanySection'

import classNames from 'classnames'
import * as sharedStyles from '../../../styles.module.css'
import * as styles from '../../../screens/listScreens/listScreens.module.css'

import { Button } from '../../shared/button/Button'
import { useNavigate } from 'react-router-dom'
import {
  getContactNamesAndNumbersFromCompanies,
  trimExtraCharacters
} from '~/src/screens/listScreens/helpers/contactSort'
import { Icon } from '../../shared/icons/Icons'
import { Heading, Text } from '../../shared/typography'
import { type SelectValue } from '../../shared/select/types'
import { type InputActionMeta } from 'react-select'

export const ListHeader = ({
  listType = ListType.Active,
  sortValue,
  setSortValue,
  setGlobalState,
  listData,
  isReadOnly,
  defaultOpenContactCategory,
  setContactSearchValue,
  contactSearchValue,
  setExportDialogOpen,
  setArchiveDialogOpen,
  toggleCompanyDialog
}: {
  listType: ListType
  sortValue: SortType
  setSortValue: (sort: SortType) => void
  setGlobalState: (setting: {
    defaultOpenContactCategory: AccordionTypes[] | undefined
  }) => void
  listData: CallList
  isReadOnly: boolean
  defaultOpenContactCategory: AccordionTypes[] | undefined
  setContactSearchValue: (val: string) => void
  contactSearchValue: string
  setExportDialogOpen: (bool: boolean) => void
  setArchiveDialogOpen?: (bool: boolean) => void
  toggleCompanyDialog?: () => void
}): JSX.Element => {
  const navigate = useNavigate()
  const [searchInputText, setSearchInputText] = useState<string>('')

  // # region gql
  const [updateActiveListSortPreference] =
    useUpdateActiveListSortPreferenceMutation()
  // # end region gql

  const handleSortChange = useCallback(
    (newSortValue: SortType) => {
      setSortValue(newSortValue)
      updateActiveListSortPreference({
        variables: {
          sortType: newSortValue
        }
      }).catch((err) => {
        // Is this something we want to trigger a user visible error for?
        console.log(err)
      })
    },
    [updateActiveListSortPreference, setSortValue]
  )

  const handleContactExpandedFilterChange = useCallback(
    (newFilterValue: AccordionTypes) => {
      setGlobalState({
        defaultOpenContactCategory: [newFilterValue]
      })
    },
    [setGlobalState]
  )

  const handleContactSearchChange = (value: string): void => {
    const searchSelection = options.find((v) => {
      return trimExtraCharacters(v.value) === trimExtraCharacters(value)
    })

    setContactSearchValue(searchSelection?.label ?? '')
  }

  const handleContactSearchOnInputChange = (
    inputText: string,
    meta: InputActionMeta
  ): void => {
    meta.action !== 'input-blur' &&
      meta.action !== 'menu-close' &&
      setSearchInputText(inputText)
  }

  const options = useMemo<Array<SelectValue<string>>>(() => {
    return getContactNamesAndNumbersFromCompanies(listData?.companies)
  }, [listData?.companies])

  useEffect(() => {
    // Clean up global state
    return () => {
      setGlobalState({
        defaultOpenContactCategory: undefined
      })
    }
  }, [setGlobalState])
  // #endregion effects

  return (
    <div
      className={classNames(
        sharedStyles.flex,
        sharedStyles['space-between'],
        sharedStyles['align-center']
      )}
    >
      <div
        className={classNames(sharedStyles.flex, sharedStyles['flex-start'])}
      >
        <div className={sharedStyles.back}>
          {/* TODO: this should be an a tag? bc its navigating, but idk the href */}
          <Button
            as='button'
            type='button'
            id='back-button'
            style='icon-only'
            ariaLabel='back to previous screen'
            onClick={(): void => {
              navigate(-1)
            }}
          >
            <Icon symbol='ArrowBackIosIcon' color='alku-red' size='md' />
          </Button>
        </div>
        <hgroup>
          {listData?.summary !== null && listData?.summary !== undefined && (
            <div
              className={classNames(
                styles['flyout-container'],
                listType === ListType.Leads && styles['icon-top-offset']
              )}
            >
              <Heading as='h1' level='one'>
                {listType === ListType.Active && (
                  <>
                    {listData?.summary.first_name} {listData?.summary.last_name}
                    {isReadOnly ? "'s Call List" : ' - Active Call List'}
                  </>
                )}
                {listType === ListType.NotCalling && (
                  <>
                    {listData?.summary.first_name} {listData?.summary.last_name}
                    {' - Not calling list'}
                  </>
                )}
                {listType === ListType.Leads && <>{'Leads List'}</>}
                {isReadOnly && listType === ListType.Active && (
                  <Favorite id={listData.user_ids.id} />
                )}
              </Heading>
              {(listType === ListType.Leads ||
                listType === ListType.Active) && (
                <div>
                  <FlyoutIconButton
                    flyoutWidth='xl'
                    id={'list-link-flyout'}
                    label={'Links for Call Lists'}
                    position='left'
                    buttonProps={{
                      style: 'link',
                      title: 'List Links',
                      children: (
                        <Icon
                          symbol='ArrowDropDownOutlinedIcon'
                          size='xl'
                          color='text-red'
                        />
                      )
                    }}
                    flyoutChildren={[
                      <FlyoutListItem
                        key={'link-active-list'}
                        buttonText={`${
                          listData?.summary.first_name as string
                        } ${
                          listData?.summary.last_name as string
                        } - Active Call List`}
                        iconSymbol='AssignmentOutlinedIcon'
                        buttonProps={{
                          id: 'link-active-list',
                          as: 'a',
                          href: `/${listData.user_ids.id}`
                        }}
                      />,
                      <FlyoutListItem
                        key={'link-leads-list'}
                        buttonText='Leads List'
                        iconSymbol='AssignmentOutlinedIcon'
                        buttonProps={{
                          id: 'link-leads-list',
                          as: 'a',
                          href: `/${listData.user_ids.id}/leads`
                        }}
                      />
                    ]}
                  />
                </div>
              )}
            </div>
          )}
          <div className={styles['callList-subtitle']}>
            <CallListHeadingSummary
              summaryType={listType}
              callList={listData}
              isReadOnly={isReadOnly}
            />

            {isReadOnly && (
              <div
                className={classNames(
                  sharedStyles.flex,
                  sharedStyles['align-center']
                )}
              >
                {' '}
                <Icon
                  symbol='VisibilityOutlinedIcon'
                  color='grey-8'
                  size='md'
                  space='center'
                />
                <Text as='p' style='detail' color='grey-8'>
                  View Only
                </Text>
              </div>
            )}
          </div>
        </hgroup>
      </div>
      <div className={styles['flyout-container']}>
        <form className={styles.filters} role='search'>
          <SelectDropdown<SortType>
            id='sort'
            name='sort'
            ariaLabel='Sort Company'
            inputLabelPrefix='Sort: '
            screenReaderOnlyLabel
            options={activeListSortData}
            value={activeListSortData.find((x) => x.value === sortValue)}
            onChange={handleSortChange}
          />
          {listType !== ListType.Leads && (
            <SelectDropdown<AccordionTypes>
              id='contact-filter'
              name='contact default'
              ariaLabel='Filter Contacts'
              screenReaderOnlyLabel
              options={contactExpandedFilterData}
              value={
                contactExpandedFilterData.find(
                  (x) => x.value === defaultOpenContactCategory?.[0]
                ) ?? contactExpandedFilterData[0]
              }
              onChange={handleContactExpandedFilterChange}
            />
          )}
          <SelectDropdown
            options={options}
            onChange={handleContactSearchChange}
            onClear={() => {
              setContactSearchValue('')
            }}
            value={options.find(
              (v) =>
                trimExtraCharacters(v.value) ===
                trimExtraCharacters(searchInputText)
            )}
            inputValue={trimExtraCharacters(searchInputText)}
            onInputChange={handleContactSearchOnInputChange}
            isSearchable
            placeholder='Search...'
            name='contact-search'
            ariaLabel='Search by Contact'
            screenReaderOnlyLabel
          />
        </form>
        {!isReadOnly && (
          <>
            {listType === ListType.Active && toggleCompanyDialog && (
              <FlyoutIconButton
                id='add-company-button'
                label='Add Company Menu'
                iconProps={{ symbol: 'AddOutlinedIcon' }}
                flyoutChildren={
                  <FlyoutListItem
                    buttonText='Add Company'
                    iconSymbol='BusinessOutlinedIcon'
                    buttonProps={{ id: 'open-add-company-dialog-button' }}
                    onClick={toggleCompanyDialog}
                  />
                }
              />
            )}
            <FlyoutIconButton
              id='more-button'
              label='More Options Menu for Your Call List'
              iconProps={{ symbol: 'MoreVertOutlinedIcon' }}
              flyoutWidth='md'
              flyoutChildren={[
                <FlyoutListItem
                  key='more-export'
                  buttonText='Export List'
                  iconSymbol='VerticalAlignBottomIcon'
                  buttonProps={{ id: 'open-export-dialog-button' }}
                  onClick={() => {
                    setExportDialogOpen(true)
                  }}
                />,
                <FlyoutListItem
                  key='more-delete'
                  buttonText='Delete List'
                  iconSymbol='DeleteOutlinedIcon'
                  buttonProps={{ id: 'open-delete-dialog-button' }}
                  onClick={() => {
                    if (setArchiveDialogOpen) setArchiveDialogOpen(true)
                  }}
                />
              ]}
            />
          </>
        )}
      </div>
    </div>
  )
}
