import { Icon } from '../icons/Icons'
import * as styles from './radio.module.css'

export interface RadioGroupProps {
  options: string[]
  selection: string
  setSelection: any
}

export const RadioGroup: React.FC<RadioGroupProps> = (props): JSX.Element => {
  // const onOptionChange = (e: any): void => {
  //   props.setSelection(e.target.value)
  // }

  return (
    <div className={styles.radio_group}>
      {props.options.map((option, key) => (
        <button
          className={styles.frame}
          key={'option-' + key.toString()}
          onClick={() => props.setSelection(option)}
        >
          {/* <input
            type='radio'
            value={option}
            // checked={props.selection === option}
            hidden
          ></input> */}
          <span
            className={
              props.selection === option ? styles.radio_checked : styles.radio
            }
          >
            <Icon
              symbol={'RadioButtonUncheckedOutlinedIcon'}
              color='alku-red'
              size='lg'
            ></Icon>
          </span>
          {option}
        </button>
      ))}
    </div>
  )
}
