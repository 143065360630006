import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined'
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined'
import BlockIcon from '@mui/icons-material/Block'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined'
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined'
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined'
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled'
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined'
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined'
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined'
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined'
import ReplayIcon from '@mui/icons-material/Replay'
import SearchIcon from '@mui/icons-material/Search'
import SpeakerNotesOffOutlinedIcon from '@mui/icons-material/SpeakerNotesOffOutlined'
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined'
import StarIcon from '@mui/icons-material/Star'
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined'
import SyncIcon from '@mui/icons-material/Sync'
import ThreePOutlinedIcon from '@mui/icons-material/ThreePOutlined'
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined'
import CircleIcon from '@mui/icons-material/Circle'
// TODO: use keyof instead of type?
// is there a way we can really only rely on the icon that needs to be imported
// (i.e. passing in the material JSX component to a prop instead of the string..)

export type IconOptions =
  | 'CancelIcon' // icon-input-clear
  | 'ArrowBackIosIcon' // icon-back
  | 'BusinessOutlinedIcon' // icon-company
  | 'WorkOutlineOutlinedIcon' // deviation from icon-job-seeker
  | 'ExitToAppOutlinedIcon' // added to manage Move to Active on DNC Company Menu
  | 'DeleteOutlinedIcon' // deviation from icon-delete
  | 'EditOutlinedIcon' // icon-edit
  | 'ChevronRightOutlinedIcon' // icon-chevron-right
  | 'ChevronLeftOutlinedIcon' // icon-chevron-left
  | 'ExpandLessOutlinedIcon' // icon-chevron-up
  | 'ExpandMoreOutlinedIcon' // icon-chevron-down
  | 'ArrowDropDownOutlinedIcon' // icon-select-arrow
  | 'PersonAddAlt1OutlinedIcon' // deviation from icon-add-to-active
  | 'AutorenewOutlinedIcon' // icon-saving
  | 'CheckCircleOutlinedIcon' // icon-check-outline
  | 'LogoutOutlinedIcon' // deviation from icon-sign-out
  | 'AddOutlinedIcon' // icon-plus
  | 'MoreVertOutlinedIcon' // icon-menu
  | 'WarningAmberRoundedIcon' // icon-warning
  | 'CloseOutlinedIcon' // icon-close
  | 'VisibilityOutlinedIcon' // icon-view-only
  | 'VerticalAlignBottomIcon' // icon-download
  | 'BlockIcon' // new: disqualify
  | 'RadioButtonCheckedOutlinedIcon'
  | 'RadioButtonUncheckedOutlinedIcon'
  | 'ReplayIcon' // new: requalify
  | 'ThreePOutlinedIcon' // new: referral
  | 'PostAddOutlinedIcon' // deviation from icon-add-to-dba
  | 'InfoOutlinedIcon' // icon-info
  | 'ErrorOutlineOutlinedIcon' // icon-alert
  | 'ContentCopyOutlinedIcon' // icon-copy
  | 'StarBorderOutlinedIcon' // icon-star-outline
  | 'StarIcon' // icon-start-filled
  | 'SearchIcon' // icon-search
  | 'FileUploadOutlinedIcon' // icon-upload
  | 'AttachFileOutlinedIcon' // icon-attachment
  | 'PhoneDisabledIcon' // new: move to not calling
  | 'AddCommentOutlinedIcon' // new: deviation from mark as referral
  | 'SpeakerNotesOffOutlinedIcon' // new: deviation from unmark referral
  | 'QuestionMarkOutlinedIcon' // new: fallback if component symbol is undefined
  | 'ControlPointIcon' // new: move to active call list
  | 'SyncIcon' // new: refresh or requalify dba
  | 'LocalFireDepartmentIcon' // new: hot lead
  | 'MyLocationOutlinedIcon' // new: marketplace info
  | 'StoreOutlinedIcon' // new: yellow lead
  | 'ConnectWithoutContactOutlinedIcon' // new: reference lead icon
  | 'AssignmentOutlinedIcon' // list tabs clipboard
  | 'CircleIcon' // new: circle icon
  | 'PeopleOutlinedIcon' // Dupe contact icon

export const iconOptions = {
  CancelIcon,
  ArrowBackIosIcon,
  BusinessOutlinedIcon,
  WorkOutlineOutlinedIcon,
  ExitToAppOutlinedIcon,
  DeleteOutlinedIcon,
  EditOutlinedIcon,
  ChevronRightOutlinedIcon,
  ChevronLeftOutlinedIcon,
  ExpandLessOutlinedIcon,
  ExpandMoreOutlinedIcon,
  ArrowDropDownOutlinedIcon,
  PersonAddAlt1OutlinedIcon,
  AutorenewOutlinedIcon,
  CheckCircleOutlinedIcon,
  LogoutOutlinedIcon,
  AddOutlinedIcon,
  MoreVertOutlinedIcon,
  WarningAmberRoundedIcon,
  CloseOutlinedIcon,
  VisibilityOutlinedIcon,
  VerticalAlignBottomIcon,
  BlockIcon,
  RadioButtonCheckedOutlinedIcon,
  RadioButtonUncheckedOutlinedIcon,
  ReplayIcon,
  ThreePOutlinedIcon,
  PostAddOutlinedIcon,
  InfoOutlinedIcon,
  ErrorOutlineOutlinedIcon,
  ContentCopyOutlinedIcon,
  StarBorderOutlinedIcon,
  StarIcon,
  SearchIcon,
  FileUploadOutlinedIcon,
  AttachFileOutlinedIcon,
  PhoneDisabledIcon,
  AddCommentOutlinedIcon,
  SpeakerNotesOffOutlinedIcon,
  QuestionMarkOutlinedIcon,
  ControlPointIcon,
  SyncIcon,
  LocalFireDepartmentIcon,
  MyLocationOutlinedIcon,
  StoreOutlinedIcon,
  ConnectWithoutContactOutlinedIcon,
  AssignmentOutlinedIcon,
  CircleIcon,
  PeopleOutlinedIcon
}
