/*
  Alert:
  Component Styles
*/

.container {
  color: var(--black from global);
  border-radius: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.error {
    background: var(--fill-error from global);
    box-shadow: 0 0.1rem 0.1rem 0 rgba(102, 0, 12, 0.3);
  }

  &.success {
    background: var(--fill-success from global);
    box-shadow: 0 0.1rem 0.1rem 0 rgba(25, 52, 0, 0.3);
  }

  &.info {
    background: var(--fill-info from global);
    box-shadow: 0 0.1rem 0.1rem 0 rgba(25, 52, 0, 0.3);
  }

  &.alert {
    background: var(--fill-warn from global);
    box-shadow: 0 0.1rem 0.1rem 0 rgba(89, 47, 0, 0.3);
  }

  &.sm {
    font-size: 1.3rem;
    padding: 1.2rem;
    font-weight: 400;
  }

  &.md {
    padding: 1.8rem 2.4rem;
    font-weight: 600;
  }
}

.content {
  display: flex;
  align-items: center;
  text-wrap: wrap;
  text-align: left;
}

.text {
  padding-left: 1rem;
  line-height: 1.5;
  & strong {
    font-weight: bold;
  }
}
