import { Heading, Text } from '../typography'
import * as styles from './dupes.module.css'
import * as sharedStyles from '../../../styles.module.css'
import classNames from 'classnames'
import {
  type BullhornDuplicateContact,
  ContactStatus,
  type AmclDuplicateContact
} from '~/src/__generated__/gql-types'
import { Icon } from '../icons/Icons'
import { formatStatus } from './formatStatus'

export const ContactDupeRow = ({
  data,
  width = 'default'
}: {
  data: AmclDuplicateContact | BullhornDuplicateContact
  width?: 'default' | 'narrow'
}): JSX.Element => {
  return (
    <div
      className={classNames([styles.dupe, styles['dupe-row'], styles[width]])}
    >
      <div
        className={classNames(
          width === 'default' && sharedStyles.flex,
          width === 'default' && sharedStyles['align-center'],
          width === 'default' && sharedStyles['space-between']
        )}
      >
        <Heading level='five' as='h3'>
          {data.owner.first_name} {data.owner.last_name}{' '}
          <Text style='five' as='span' weight='regular'>
            ({data.owner.division ?? 'Unknown'})
          </Text>
        </Heading>
        <div
          className={classNames(
            width === 'default' && sharedStyles.flex,
            width === 'narrow' && styles.mt8,
            styles['contact-row-content']
          )}
        >
          <div className={classNames(width === 'narrow' && sharedStyles.flex)}>
            <Text style='detail' as='p'>
              Status:
            </Text>
            <Text style='detail' as='p' weight='bold'>
              <span
                className={classNames(
                  sharedStyles.flex,
                  sharedStyles['align-center'],
                  width === 'narrow' && styles.space
                )}
              >
                {formatStatus(data.status)}
                <Icon
                  symbol='CircleIcon'
                  color={
                    data.status === ContactStatus.Dba ||
                    data.status === ContactStatus.Requalified
                      ? 'text-green'
                      : data.status === ContactStatus.Disqualified
                      ? 'alku-red'
                      : 'bright-blue'
                  }
                  size='xs'
                  space='left'
                />
              </span>
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}
