import classNames from 'classnames'

import { Button } from '../../shared/button/Button'
import * as styles from './uploadListForm.module.css'
import * as animationStyles from '../../../animation.module.css'
import { FileInput, FileInputType } from '../../shared/forms/form-elements'
import { useEffect, useState } from 'react'
import { Alert } from '../../shared/alert/Alert'
import { getAccessToken } from '../../../auth/msal/helpers'
import { Heading, Text } from '../../shared/typography'
import { Icon } from '../../shared/icons/Icons'

const { REST_API_BASEURL } = process.env

export const UploadListForm = ({
  closeModal,
  refreshCallList
}: {
  closeModal: () => void
  refreshCallList: () => void
}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false)
  const [successfulUpload, setSuccessfulUpload] = useState<boolean>(false)
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine)
  const [fileUploaded, setFileUploaded] = useState<File>()
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true)
  const [uploadError, setUploadError] = useState<{
    hasError: boolean
    errors: string
  }>({
    hasError: false,
    errors: ''
  })

  const imageUrl = new URL(
    '../../shared/assets/synchronize.min.svg',
    import.meta.url
  )

  const handleUploadSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()
    if (fileUploaded !== null && fileUploaded !== undefined) {
      const token = (await getAccessToken()) as string
      /* check if file is csv */
      if (fileUploaded.type !== FileInputType.CSV) {
        setUploadError({
          hasError: true,
          errors: 'Invalid file type. Please upload a CSV file.'
        })
      } else {
        /* if not have errors submit csv */
        setLoading(true)
        const data = new FormData()
        data.append('csvFile', fileUploaded)
        fetch(`${REST_API_BASEURL}/upload`, {
          method: 'POST',
          body: data,
          headers: {
            'content-length': `${fileUploaded.size}`,
            Authorization: `Bearer ${token}`
          }
        })
          .then(async (res) => await res.json())
          .then((data) => {
            if (data.success === true) {
              triggerGtmEvent()
              setUploadError({ hasError: false, errors: '' })
              setLoading(false)
              setSuccessfulUpload(true)
              refreshCallList()
              closeModal()
            } else {
              // catch error with upload
              setLoading(false)
              setUploadError({
                hasError: true,
                errors:
                  data?.message ??
                  'Unable to process file, please try again later'
              })
            }
          })
          .catch((err) => {
            /* catch error with fetch */
            setLoading(false)
            setUploadError({
              hasError: true,
              errors:
                err?.message ?? 'Unable to process file, please try again later'
            })
          })
      }
    }
  }
  const triggerGtmEvent = (): void => {
    window.dataLayer.push({
      event: 'ga-upload-contacts'
    })
  }

  useEffect(() => {
    const onlineHandler = (): void => {
      setIsOnline(true)
    }

    const offlineHandler = (): void => {
      setIsOnline(false)
    }

    window.addEventListener('online', onlineHandler)
    window.addEventListener('offline', offlineHandler)

    return () => {
      window.removeEventListener('online', onlineHandler)
      window.removeEventListener('offline', offlineHandler)
    }
  }, [setIsOnline])

  return (
    <>
      <div
        className={classNames(
          styles['modal-title'],
          (loading || uploadError.hasError) && styles.center
        )}
      >
        {uploadError.hasError && (
          <Icon
            symbol='WarningAmberRoundedIcon'
            color='alku-red'
            size='xl'
            space='right'
          />
        )}
        <Heading as='h3' level='three'>
          {uploadError.hasError && 'There was a Problem Building the list'}
          {!uploadError.hasError &&
            (!loading ? 'Upload to Your List' : 'Now building your call list')}
        </Heading>
      </div>
      {loading && (
        <div className={styles.center}>
          <div className={styles['loading-container']}>
            <img
              className={classNames(animationStyles.spin, styles.loading)}
              src={imageUrl.toString()}
              alt='AMCL The AM Call List'
            />
          </div>
          <Text as='p' style='four'>
            You will be brought to the list momentarily...
          </Text>
        </div>
      )}
      {successfulUpload && (
        <div className='upload-success'>
          <Heading as='h2' level='four'>
            Upload completed!
          </Heading>
          <br />
          <br />
          <Button
            as='button'
            style='primary'
            type='submit'
            id='close-upload'
            onClick={closeModal}
          >
            Close
          </Button>
        </div>
      )}
      {!loading && !successfulUpload && (
        <>
          {!isOnline && (
            <>
              <Alert type='error' id='connection-error'>
                There was a problem connecting to the server. Please try again
                in a moment
              </Alert>
              <br />
            </>
          )}
          {uploadError.hasError && (
            <>
              <div className={styles.center}>{uploadError.errors}</div>
              <br />
              <br />
            </>
          )}
          {!uploadError.hasError ? (
            <form
              id='upload-list-form'
              className={styles.form}
              onSubmit={(e) => {
                handleUploadSubmit(e).catch((err) => {
                  // catch error with token or another not catched error
                  setUploadError({
                    hasError: true,
                    errors:
                      err?.message ??
                      'Unable to process file, please try again later'
                  })
                })
              }}
              method='post'
            >
              <div className={styles['form-element']}>
                <FileInput
                  label={'Upload contacts'}
                  id='upload-button'
                  accept={FileInputType.CSV}
                  onChange={(e) => {
                    setDisableSubmit(false)
                  }}
                  fileUploaded={fileUploaded}
                  setFileUploaded={setFileUploaded}
                />
              </div>
              <Button
                style='primary'
                type='submit'
                as='button'
                ariaLabel='submit upload list form'
                id='submit-upload-list-form'
                disabled={disableSubmit || !isOnline}
              >
                Submit
              </Button>
            </form>
          ) : (
            <div className={styles.center}>
              <Button
                style='primary'
                type='submit'
                as='button'
                ariaLabel='Reset Form'
                id='reset-form'
                onClick={() => {
                  setUploadError({ hasError: false, errors: '' })
                }}
              >
                Go Back
              </Button>
            </div>
          )}
        </>
      )}
    </>
  )
}
