import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { Container } from '../../components/shared/container/Container'
import { Heading, Text } from '../../components/shared/typography'
import { Button } from '../../components/shared/button/Button'
import { Icon } from '../../components/shared/icons/Icons'
import { useAuth } from '../../auth/hooks/useAuth'
import { Modal } from '../../components/shared/modal/Modal'
import { UploadListForm } from '../../components/call-list/uploadListForm/UploadListForm'

import {
  type Company,
  SortType,
  useGetCurrentUserQuery,
  type CallList,
  ListType,
  useGetActiveListLazyQuery
} from '../../__generated__/gql-types'

/* Styles */
import classNames from 'classnames'
import * as styles from './listScreens.module.css'
import * as animationStyles from '../../animation.module.css'
import { CompanyForm } from '../../components/call-list/CompanyForm/CompanyForm'

/* Filter Data */
import {
  ArchiveCallListModal,
  ExportCallListModal
} from '../../components/call-list/ExportCallListModal/ExportCallListModal'
import { Alert } from '~/src/components/shared/alert/Alert'
import { ListHeader } from '~/src/components/call-list/ListHeader/ListHeader'
import { useGlobalState } from '~/src/auth/hooks/useGlobal'
import { Companies } from '~/src/components/call-list/Companies/Companies'
import { type SCREEN_STATE } from './helpers/list.helper'

const testId = 'not-a-real-active-id'
const imageUrl = new URL(
  '../../components/shared/assets/synchronize.min.svg',
  import.meta.url
)
export const ActiveList = (): JSX.Element => {
  const { data: userData } = useGetCurrentUserQuery()
  const params = useParams()
  const navigate = useNavigate()
  const { id } = useAuth()
  const isReadOnly = id !== params?.id && params?.id !== testId

  // #region state
  const [createNewCompany, setCreateNewCompany] = useState(false)
  const [companyDialogOpen, setCompanyDialogOpen] = useState(false)
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false)
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false)
  const [exportDialogOpen, setExportDialogOpen] = useState(false)
  const [editingCompany, setEditingCompany] = useState<Company | null>(null)
  const [screenState, setScreenState] =
    useState<keyof typeof SCREEN_STATE>('DEFAULT')
  const [sortValue, setSortValue] = useState<SortType>(
    userData?.currentUser?.call_list_sort_by ?? SortType.Alpha
  )
  const { setGlobalState, defaultOpenContactCategory } = useGlobalState()
  // #endregion state

  // #region gql
  const [getActiveList, { data: listData, loading: callListLoading, called }] =
    useGetActiveListLazyQuery({
      variables: {
        userId: typeof params?.id === 'string' ? params.id : ''
      }
    })

  const [contactSearchValue, setContactSearchValue] = useState<string>('')

  // #endregion gql

  // #region effects
  useEffect(() => {
    if (params?.id !== testId) {
      if (
        called &&
        !callListLoading &&
        (listData == null || listData === undefined)
      )
        navigate('/call-list/not-found')
    }
  }, [called, callListLoading, listData, navigate, params?.id])

  useEffect(() => {
    void getActiveList({
      variables: {
        userId: typeof params?.id === 'string' ? params.id : ''
      }
    })
  }, [getActiveList, params.id])

  // #region handlers
  const refreshCallList = (): void => {
    setTimeout(() => {
      void getActiveList({
        fetchPolicy: 'cache-and-network'
      })
    }, 1000)
  }
  const handleCompanyModalClose = (): void => {
    toggleCompanyDialog()
    setCreateNewCompany(false)
    setEditingCompany(null)
  }
  const toggleCompanyDialog = (): void => {
    setCompanyDialogOpen((prevState) => !prevState)
  }
  const toggleUploadDialog = (): void => {
    setUploadDialogOpen((prevState) => !prevState)
  }
  // #endregion handlers

  // #region computed data
  const isEditingCompany = editingCompany != null

  const data = listData?.getActiveList as CallList
  // #endregion computed data
  return (
    <>
      <Container>
        <ListHeader
          listType={ListType.Active}
          sortValue={sortValue}
          setSortValue={setSortValue}
          setGlobalState={setGlobalState}
          listData={data}
          isReadOnly={isReadOnly}
          defaultOpenContactCategory={defaultOpenContactCategory}
          setContactSearchValue={setContactSearchValue}
          contactSearchValue={contactSearchValue}
          setExportDialogOpen={setExportDialogOpen}
          setArchiveDialogOpen={setArchiveDialogOpen}
          toggleCompanyDialog={toggleCompanyDialog}
        />
        {listData?.getActiveList === null && (
          <Text as='p' style='detail' color='grey-8'>
            An error occurred while loading your call list. Please try again
          </Text>
        )}
        {(screenState === 'ADDING' || screenState === 'UPDATING') && (
          <div className={styles['loading-container']}>
            <Text as='p' style='one' weight='bold'>
              {screenState === 'ADDING' ? 'Adding new' : 'Updating'} company...
            </Text>
            <br />
            <img
              className={classNames(animationStyles.spin)}
              src={imageUrl.toString()}
              alt='AMCL The AM Call List'
            />
          </div>
        )}
        {screenState === 'ERROR' && (
          <>
            <br />
            <Alert type='error' id='active-list-screen-error'>
              There was an error updating your call list.
            </Alert>
          </>
        )}
        {screenState === 'DEFAULT' &&
          listData?.getActiveList?.companies != null &&
          listData.getActiveList.companies.length === 0 && (
            <div className={styles['company-container']}>
              <Heading as='h2' level='four'>
                It’s a little lonely here.{' '}
                {!isReadOnly && <>Add a company or upload a list…</>}
              </Heading>
              {!isReadOnly && (
                <>
                  <Button
                    as='button'
                    type='button'
                    id='open-company-dialog-button'
                    style='secondary'
                    onClick={toggleCompanyDialog}
                  >
                    <Icon
                      symbol='AddOutlinedIcon'
                      color='alku-red'
                      size='md'
                      space='right'
                    />{' '}
                    Add Company
                  </Button>
                  <Button
                    as='button'
                    type='button'
                    style='secondary'
                    id='open-upload-dialog-button'
                    onClick={toggleUploadDialog}
                  >
                    <Icon
                      symbol='FileUploadOutlinedIcon'
                      color='alku-red'
                      size='md'
                      space='right'
                    />
                    Upload a list
                  </Button>
                </>
              )}
            </div>
          )}
        {listData?.getActiveList.companies &&
          listData?.getActiveList.companies.length > 0 && (
            <Companies
              listType={ListType.Active}
              companiesData={listData?.getActiveList.companies}
              userId={listData?.getActiveList.user_ids.id}
              sortValue={sortValue}
              defaultOpenContactCategory={defaultOpenContactCategory}
              isReadOnly={isReadOnly}
              contactSearchValue={contactSearchValue}
              setEditingCompany={setEditingCompany}
              setEditingLead={() => {}}
              setCompanyDialogOpen={setCompanyDialogOpen}
              setScreenState={setScreenState}
            />
          )}
      </Container>
      <Modal
        open={companyDialogOpen}
        onRequestClose={handleCompanyModalClose}
        closeOnOutsideClick={false}
        title={
          createNewCompany
            ? 'Add a New Company'
            : `${isEditingCompany ? 'Edit' : 'Add a'} Company`
        }
      >
        {companyDialogOpen && (
          <CompanyForm
            toggleCreateNew={(status) => {
              setCreateNewCompany(status)
            }}
            closeDialog={handleCompanyModalClose}
            companyData={editingCompany}
            createNewCompany={createNewCompany}
            setCreateNewCompany={setCreateNewCompany}
            setScreenState={setScreenState}
            editMode={isEditingCompany}
            callListId={listData?.getActiveList?.user_ids?.id as string}
          />
        )}
      </Modal>
      <Modal
        open={uploadDialogOpen}
        onRequestClose={toggleUploadDialog}
        closeOnOutsideClick={false}
      >
        <UploadListForm
          closeModal={toggleUploadDialog}
          refreshCallList={refreshCallList}
        />
      </Modal>
      <ArchiveCallListModal
        listType={ListType.Active}
        isOpen={archiveDialogOpen}
        setIsOpen={setArchiveDialogOpen}
        callListUserId={listData?.getActiveList?.user_ids?.id as string}
      />
      <ExportCallListModal
        listType={ListType.Active}
        isOpen={exportDialogOpen}
        setIsOpen={setExportDialogOpen}
      />
    </>
  )
}
