import { type FC, useState } from 'react'
import { type TogglePopover } from '../../../table/Table'
import {
  type Contact,
  type Company,
  type ListType
} from '~/src/__generated__/gql-types'
import { AddDBAForm } from '../AddDBAForm/AddDBAForm'
import { RQDBAForm } from '../RQDBAForm/RQDBAForm'
import { AddExistingDBAForm } from '../AddExistingDBAForm/AddExistingDBAForm'
import { RadioGroup } from '~/src/components/shared/radio/RadioGroup'

interface SelectDBAFormProps {
  contact: Contact
  onRequestClose: () => void
  togglePopover: TogglePopover
  listType: ListType
  companyObject: Company
}

export const SelectDBAForm: FC<SelectDBAFormProps> = (props) => {
  const { contact, onRequestClose, togglePopover, listType, companyObject } =
    props

  const options = ['Add New DBA', 'Add Requalified DBA', 'Add Pre-Existing DBA']

  const [selection, setSelection] = useState('')

  return (
    <div>
      <RadioGroup
        options={options}
        selection={selection}
        setSelection={setSelection}
      ></RadioGroup>
      {selection === options[0] ? (
        <AddDBAForm
          contact={contact}
          onRequestClose={onRequestClose}
          togglePopover={togglePopover}
          listType={listType}
          companyObject={companyObject}
        ></AddDBAForm>
      ) : (
        <></>
      )}
      {selection === options[1] ? (
        <RQDBAForm
          contact={contact}
          onRequestClose={onRequestClose}
          togglePopover={togglePopover}
          companyObject={companyObject}
        ></RQDBAForm>
      ) : (
        <></>
      )}
      {selection === options[2] ? (
        <AddExistingDBAForm
          contact={contact}
          onRequestClose={onRequestClose}
          togglePopover={togglePopover}
          companyObject={companyObject}
        ></AddExistingDBAForm>
      ) : (
        <></>
      )}
    </div>
  )
}
