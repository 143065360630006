import { useMsal } from '@azure/msal-react'
import { type IPublicClientApplication } from '@azure/msal-browser'
import { Link, NavLink } from 'react-router-dom'
import { Text } from '../shared/typography'
import { Icon } from '../shared/icons/Icons'
import { Avatar } from '../shared/avatar/Avatar'
import * as styles from './header.module.css'
import { useAuth } from '../../auth/hooks/useAuth'
import { Flyout } from '../shared/flyout/Flyout'
import classNames from 'classnames'
import { useState } from 'react'
import { Button } from '../shared/button/Button'
import * as sharedStyles from '../../styles.module.css'
import featureFlags, { environment } from '~/src/config/config'

/**
 * Header
 */

export const Header = (): JSX.Element => {
  const { instance } = useMsal()
  const imageUrl = new URL('assets/amcl-logo.svg', import.meta.url)
  const [logoutFlyoutOpen, setLogoutFlyoutOpen] = useState(false)

  const handleLogout = (
    logoutType: string,
    instance: IPublicClientApplication
  ): void => {
    if (logoutType === 'popup') {
      void instance.logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/'
      })
    } else if (logoutType === 'redirect') {
      void instance.logoutRedirect({
        postLogoutRedirectUri: '/'
      })
    }
  }

  const toggleLogoutOpen = (): void => {
    setLogoutFlyoutOpen((bool) => !bool)
  }

  const { first_name, last_name } = useAuth()

  const getInitials = (first_name: string, last_name: string): string => {
    return (
      first_name.substring(0, 1).toUpperCase() +
      last_name.substring(0, 1).toUpperCase()
    )
  }

  return (
    <div className={styles.header}>
      <div className={styles['logo-container']}>
        <Link to='/' className={styles.logo}>
          <img
            className={styles.img}
            src={imageUrl.toString()}
            alt='AMCL The AM Call List'
          />
        </Link>
        {featureFlags.showEnvironmentBadge && (
          <div className={styles.environment}>
            <span>{environment.toUpperCase()}</span>
          </div>
        )}
      </div>

      <nav aria-label='Main' id='main-nav' className={styles.nav}>
        <Link to='/' className={styles['logo-mobile']}>
          <img
            className={styles.img}
            src={imageUrl.toString()}
            alt='AMCL The AM Call List'
          />
        </Link>
        <button
          className={styles.profile}
          onClick={toggleLogoutOpen}
          aria-label='Sign Out'
        >
          <Text as='div' style='detail' color='dark-blue'>
            <div className={styles.avatar}>
              <Avatar initials={getInitials(first_name, last_name)} size='sm' />
              {first_name} {last_name}{' '}
            </div>
          </Text>
          <Icon
            symbol='ArrowDropDownOutlinedIcon'
            color='alku-red'
            size='lg'
            space='center'
            rotate={logoutFlyoutOpen}
          />
        </button>
        <div className={styles['account-wrapper']}>
          <Flyout
            type='account'
            isOpen={logoutFlyoutOpen}
            setIsOpen={setLogoutFlyoutOpen}
            width='md'
          >
            <ul>
              <li key='item-1' className={sharedStyles['flyout-item']}>
                <Button
                  id='profle-flyout-btn'
                  as='button'
                  style='icon-only'
                  onClick={() => {
                    handleLogout('redirect', instance)
                  }}
                >
                  <Icon
                    symbol='LogoutOutlinedIcon'
                    color='alku-red'
                    size='md'
                    space='right'
                  />
                  <Text
                    as='span'
                    style='detail'
                    color='dark-blue'
                    weight='semibold'
                  >
                    Sign Out
                  </Text>
                </Button>
              </li>
            </ul>
          </Flyout>
        </div>

        <ul className={styles.menu}>
          <li>
            <NavLink
              to='/'
              className={({ isActive }) =>
                classNames(styles.link, isActive ? styles.active : '')
              }
            >
              {({ isActive }) => (
                <Text
                  as='div'
                  style='nav-text'
                  color='black'
                  weight={isActive ? 'bold' : 'semibold'}
                >
                  My Lists
                </Text>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/directory'
              className={({ isActive }) =>
                classNames(styles.link, isActive ? styles.active : '')
              }
            >
              {({ isActive }) => (
                <Text
                  as='div'
                  style='nav-text'
                  color='black'
                  weight={isActive ? 'bold' : 'semibold'}
                >
                  Directory
                </Text>
              )}
            </NavLink>
          </li>
          {featureFlags.uiKit && (
            <li>
              <NavLink
                to='/ui-kit'
                className={({ isActive }) =>
                  classNames(styles.link, isActive ? styles.active : '')
                }
              >
                {({ isActive }) => (
                  <Text
                    as='div'
                    style='nav-text'
                    color='black'
                    weight={isActive ? 'bold' : 'semibold'}
                  >
                    UI Kit
                  </Text>
                )}
              </NavLink>
            </li>
          )}
        </ul>
      </nav>
    </div>
  )
}
