import { Icon } from '../../components/shared/icons/Icons'
import * as styles from './notFound.module.css'

export const NotFound = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>404</h1>
      <Icon symbol='WorkOutlineOutlinedIcon' color='alku-red' size='xxxl' />
      <div className={styles.detail}>
        <p>
          The page you’re looking for can’t be found. Please use the navigation
          at the top of the page.
        </p>
      </div>
    </div>
  )
}
