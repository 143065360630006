import { Heading, Text } from '../components/shared/typography'
import { Button } from '../components/shared/button/Button'
import { Avatar } from '../components/shared/avatar/Avatar'
import * as sharedStyles from '../styles.module.css'
import { Icon } from '../components/shared/icons/Icons'
import { ExampleComponentWithModal } from '../components/modal-example/ExampleComponentWithModal'
import { Tooltip } from '../components/shared/tooltip/Tooltip'
import { Tile } from '../components/shared/tile/Tile'
import { Alert } from '../components/shared/alert/Alert'
import { ExampleComponentsWithFlyout } from '../components/flyout-example/ExampleComponentsWithFlyout'
import { Container } from '../components/shared/container/Container'
import { SelectDropdown } from '../components/shared/select/SelectDropdown'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  type CallListSummary,
  type AddCompanyInput,
  type AddContactInput,
  ContactStatus,
  type AmclDuplicateCompany,
  type BullhornDuplicateCompany,
  type AmclDuplicateContact,
  type BullhornDuplicateContact,
  DuplicateType
} from '../__generated__/gql-types'
import { TileType } from '../components/shared/select/types'
import { CompanyDupeCard } from '../components/shared/dupes/CompanyDupeCard'
import { CompanyDupeRow } from '../components/shared/dupes/CompanyDupeRow'
import { ContactDupeCard } from '../components/shared/dupes/ContactDupeCard'
import { ContactDupeRow } from '../components/shared/dupes/ContactDupeRow'

export function UIKit(): JSX.Element {
  const myListTileData = {
    companies_count: 25,
    contacts_count: 528,
    dba_count: 82,
    disqualified_count: 106,
    first_name: 'ALKU',
    last_name: 'Everywhere',
    user_division: 'Cerner',
    id: '1100101',
    user_ids: {
      bullhorn_id: 100001,
      id: '10001011'
    }
  }

  const defaultTileData: CallListSummary = {
    companies_count: 25,
    contacts_count: 528,
    dba_count: 82,
    disqualified_count: 106,
    first_name: 'ALKU',
    last_name: 'Everywhere',
    user_division: 'Kronos',
    id: '1100102',
    user_ids: {
      bullhorn_id: 100002,
      id: '10001012'
    }
  }

  const options1 = [
    { value: 'EST', label: 'EST' },
    { value: 'CST', label: 'CST' },
    { value: 'MST', label: 'MST' },
    { value: 'PST', label: 'PST' }
  ]

  const options2 = [
    { value: 'Active Contacts', label: 'Active Contacts' },
    { value: 'DBA Contacts', label: 'DBA Contacts' },
    { value: 'Disqualified Contacts', label: 'Disqualified Contacts' }
  ]

  const options4 = [
    { value: 'FirstName LastName', label: 'FirstName LastName' },
    { value: 'Jane Doe', label: 'Jane Doe' },
    { value: 'ALKU Everywhere', label: 'ALKU Everywhere' },
    { value: 'John Doe', label: 'John Doe' }
  ]

  const options3 = [
    { value: 'AcmeCorp.', label: 'AcmeCorp.' },
    { value: 'Atlantic Enterprises', label: 'Atlantic Enterprises' },
    { value: 'Atlassian Inc.', label: 'Atlassian Inc.' }
  ]

  const selectInitialValues = { company: '' }

  const validationSchema = Yup.object({
    company: Yup.string().required('Required').label('Company')
  })

  const AmclCompanyDupeData: AmclDuplicateCompany = {
    id: 'ca24d7dd-3bd9-4a6d-8110-7389b1f2e41a',
    created_ts: 1713383654000,
    contacts_count: 5,
    owner: {
      id: '012345',
      first_name: 'Travis',
      last_name: 'Smith',
      division: 'Cerner'
    }
  }

  const yourCompanyData: AddCompanyInput = {
    bullhorn_id: 180649,
    name: 'Eliassen Group, Inc.',
    address1: '30 Audubon Road',
    address2: '',
    city: 'Wakefield',
    state: 'MA',
    postal: '01880',
    timezone: null,
    phone: '781 246 1600',
    phone2: '',
    linkedin: '',
    notes: ''
  }

  const yourContactData: AddContactInput = {
    first_name: 'Keith',
    last_name: 'Parsons',
    company_id: '12345',
    title: null
  }

  const bhDupeData: BullhornDuplicateCompany = {
    name: 'Acme Corporation',
    address1: '887 South Street',
    address2: 'Suite 5B',
    city: 'Wellesley',
    state: 'MA',
    postal: '02116',
    phone: '123-123-1234',
    id: 'some-id-1'
  }

  const contactBhDuplicateData: BullhornDuplicateContact = {
    id: 'some-id-2',
    first_name: 'Keith',
    last_name: 'Parson',
    company_name: 'Apple Inc.',
    title: '',
    phone: '',
    email: '',
    owner: {
      id: '123455',
      first_name: 'House',
      last_name: '1',
      division: 'Andover'
    }
  }

  const contactAmclDuplicateData: AmclDuplicateContact = {
    id: 'some-id-3',
    bullhorn_id: 1230123,
    first_name: 'Keith',
    last_name: 'Parson',
    company_name: 'Apple Inc.',
    title: '',
    phone: '',
    email: '',
    status: ContactStatus.Dba,
    notes: 'Some notes here.',
    type: DuplicateType.AmclSoftContact,
    owner: {
      id: '123455',
      first_name: 'Melissa',
      last_name: 'Adams',
      division: 'Workday Financials'
    }
  }

  const contactAmclExactDuplicate: AmclDuplicateContact = {
    first_name: 'Keith',
    last_name: 'Parsons',
    id: 'some-id-4',
    company_name: 'Company Name',
    status: ContactStatus.Disqualified,
    type: DuplicateType.AmclHardContact,
    owner: {
      id: '123455',
      first_name: 'Stu',
      last_name: 'Gold',
      division: 'SAP'
    }
  }

  return (
    <Container>
      <Heading as='div' level='one'>
        UI Kit
      </Heading>
      <hr />
      <Heading level='two' as='h2'>
        Typography
      </Heading>
      <br />
      <Heading level='three' as='h2'>
        Headings
      </Heading>
      <br />
      <Heading as='div' level='one'>
        My Lists (Heading 1)
      </Heading>
      <Heading as='h2' level='two'>
        My People’s Lists (Heading 2)
      </Heading>
      <Heading as='h3' level='three'>
        Modal Heading
      </Heading>
      <hr />
      <p>Nav Text:</p>
      <br />
      <Text href='#' as='a' style='nav-text' color='black' weight='semibold'>
        Directory
      </Text>
      <br />
      <hr />
      <br />
      <p>Detail Text: consolidated</p>
      <br />
      <Text as='div' style='detail' color='dark-blue'>
        <Avatar initials='JD' size='sm' />
        Jane Doe
      </Text>
      <br />
      <Text as='p' style='detail' color='dark-blue'>
        42 companies 625 contacts
      </Text>
      <br />
      <Text as='p' style='detail' color='dark-blue'>
        Saved
      </Text>
      <br />
      <Text as='p' style='detail' color='dark-grey'>
        1244 Boylston Street, Suite 1200
        <br />
        Boston, MA 02164
      </Text>
      <br />
      <Text as='p' style='detail' color='dark-grey' weight='bold'>
        8 Contacts
      </Text>
      <br />
      <Text as='p' style='detail' color='bright-blue' weight='bold'>
        Active Contacts (8)
      </Text>
      <br />
      <Text as='p' style='detail' color='text-green' weight='bold'>
        DBA Contacts (8)
      </Text>
      <br />
      <Text as='p' style='detail' color='text-red' weight='bold'>
        Disqualified Contacts (5)
      </Text>
      <br />
      <hr />
      <br />
      <Text href='#' as='a' style='link' color='bright-blue' weight='semibold'>
        Company
      </Text>
      <br />
      <hr />
      <br />
      <Heading level='two' as='h2'>
        Buttons
      </Heading>
      <br />
      <p>Primary Button:</p>
      <br />
      <Button id='primary' style='primary' type='submit' as='button'>
        Submit
      </Button>
      <br />
      <br />
      <Button id='secondary' style='primary' type='submit' disabled as='button'>
        Submit Disabled
      </Button>
      <br />
      <br />
      <br />
      <p>Secondary Button: consolidated w/ upload</p>
      <br />
      <Button id='contact' as='button' type='submit' style='secondary'>
        <Icon
          symbol='AddOutlinedIcon'
          color='alku-red'
          size='lg'
          space='right'
        />{' '}
        Add Contact
      </Button>
      <br />
      <br />
      <Button id='browse' as='button' type='submit' style='secondary'>
        Browse...
      </Button>
      <br />
      <br />
      <Button id='link' as='a' style='primary' href='#'>
        Link Button
      </Button>
      <br />
      <br />
      <br />
      <p>Function Button:</p>
      <br />
      <Button id='add-button' as='button' type='button' style='function'>
        <Icon symbol='AddOutlinedIcon' color='black' size='lg' />
      </Button>
      <br />
      <br />
      <Button id='menu-button' as='button' type='button' style='function'>
        <Icon symbol='MoreVertOutlinedIcon' color='black' size='lg' />
      </Button>
      <br />
      <br />
      <br />
      <p>Icon Only Button:</p>
      <br />
      <Button
        as='button'
        style='icon-only'
        onClick={() => {
          console.log('icon only button clicked')
        }}
        id='reset-zi-credits'
      >
        <Icon symbol='AutorenewOutlinedIcon' color='alku-red' size='md' />
        Example button
      </Button>
      <hr />
      <br />
      <Heading level='two' as='h2'>
        Avatars
      </Heading>
      <br />
      <br />
      <p>Login Avatar:</p>
      <br />
      <Avatar initials='SA' size='sm' />
      <br />
      <br />
      <p>Card Avatar:</p>
      <br />
      <Avatar initials='SA' size='md' />
      <br />
      <br />
      <Heading level='two' as='h2'>
        Icons
      </Heading>
      <br />
      <br />
      {/* TODO: replace */}
      <Icon symbol='WarningAmberRoundedIcon' color='alku-red' size='md' />
      <Icon symbol='VisibilityOutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='FileUploadOutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='StarBorderOutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='StarIcon' color='alku-red' size='md' />
      <Icon symbol='LogoutOutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='ArrowDropDownOutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='SearchIcon' color='alku-red' size='md' />
      <Icon symbol='AutorenewOutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='AddOutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='MoreVertOutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='CancelIcon' color='alku-red' size='md' />
      <Icon symbol='EditOutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='VerticalAlignBottomIcon' color='alku-red' size='md' />
      <Icon symbol='BlockIcon' color='alku-red' size='md' />
      <Icon symbol='DeleteOutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='BusinessOutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='CloseOutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='ChevronLeftOutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='ChevronRightOutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='ExpandLessOutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='ExpandMoreOutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='ArrowBackIosIcon' color='alku-red' size='md' />
      <Icon symbol='AttachFileOutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='ErrorOutlineOutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='PostAddOutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='PersonAddAlt1OutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='InfoOutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='CheckCircleOutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='WorkOutlineOutlinedIcon' color='alku-red' size='md' />
      <Icon symbol='ContentCopyOutlinedIcon' color='alku-red' size='md' />
      <br />
      <br />
      <Heading level='two' as='h2'>
        Modal/Dialog
      </Heading>
      <br />
      <ExampleComponentWithModal />
      <br />
      <br />
      <Heading level='two' as='h2'>
        Tooltip
      </Heading>
      <br />
      <div>
        Favorites Tooltip{' '}
        <Tooltip
          text="Add to My People's List"
          tooltipId='tooltip-1'
          position='center'
          id='favorite-tooltip'
          onClick={() => {}}
        >
          <Icon
            symbol='StarBorderOutlinedIcon'
            color='icon-favorite'
            size='lg'
          />
        </Tooltip>
      </div>
      <br />
      <br />
      <Heading level='two' as='h2'>
        Tile
      </Heading>
      <br />
      <Tile
        tileType={TileType.ACTIVE}
        type='default'
        data={defaultTileData}
        id='000'
      />
      <br />
      <br />
      <Tile
        tileType={TileType.ACTIVE}
        type='my-list'
        data={myListTileData}
        id='001'
      />
      <br />
      <br />
      <Heading level='two' as='h2'>
        Alert
      </Heading>
      <br />
      <Alert type='error' id='demo-error'>
        There is a temporary problem connecting to the server. Please wait till
        the connection is reestablished before making changes.
      </Alert>
      <br />
      <Alert type='alert' id='demo-alert'>
        This is a warning about a potentially negative issue that might need{' '}
        <a href='/#' className={sharedStyles.hyperlink}>
          an action taken
        </a>{' '}
        but can be dismissed by the user.
      </Alert>
      <br />
      <Alert type='success' id='demo-success' dismissable>
        You’ve done something successful and this type of message can be
        dismissed.
      </Alert>
      <br />
      <Alert type='info' id='demo-info'>
        An informational message that conveys information that doesn’t represent
        a risk.
      </Alert>
      <br />
      <br />
      <Heading level='two' as='h2'>
        Dupes Cards and Rows
      </Heading>
      <br />
      <div className='width'>
        Data From Add/Create Company Form:
        <br />
        <br />
        <CompanyDupeCard type='input' data={yourCompanyData} />
        <br />
        Data From BULLHORN MATCH Based on Add/Create Company Form:
        <br />
        <br />
        <CompanyDupeCard type='bullhorn' data={bhDupeData} />
        <br />
        Data From AMCL MATCH Based on Add/Create Company Form:
        <br />
        <br />
        <CompanyDupeRow data={AmclCompanyDupeData} />
        <br />
        Data From Add Contact Form:
        <br />
        <br />
        <ContactDupeCard type='input' contact={yourContactData} />
        <br />
        Data From BULLHORN MATCH Based on Add Contact Form:
        <br />
        <br />
        <ContactDupeCard type='bullhorn' contact={contactBhDuplicateData} />
        <br />
        Data From AMCL MATCH (Partial) Based on Add Contact Form:
        <br />
        <br />
        <ContactDupeCard type='partial' contact={contactAmclDuplicateData} />
        <br />
        <br />
        Data From AMCL MATCH (Exact) Based on Add Contact Form:
        <br />
        <br />
        <ContactDupeRow data={contactAmclExactDuplicate} />
      </div>
      <br />
      <br />
      <Heading level='two' as='h2'>
        Flyout Menu
      </Heading>
      <br />
      <ExampleComponentsWithFlyout />
      <br />
      <br />
      <br />
      <Heading level='two' as='h2'>
        Custom Selects
      </Heading>
      <Formik
        initialValues={selectInitialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values)
        }}
      >
        {({ errors, isSubmitting, touched, handleChange, values }) => (
          <>
            <br />
            <SelectDropdown
              label='company dropdown example 1'
              placeholder='Select:'
              options={options1}
              name='company'
            />
            <br />
            <br />
            <br />
            <SelectDropdown
              label='company dropdown example 2'
              defaultOptionSelected
              options={options2}
              name='company'
            />
            <br />
            <br />
            <br />
            <SelectDropdown
              label='company search example one'
              options={options4}
              isSearchable
              placeholder='Search...'
              name='company'
            />
            <br />
            <br />
            <br />
            <SelectDropdown
              label='company search example 2'
              options={options3}
              isSearchable
              placeholder='Enter company name...'
              name='company'
            />
          </>
        )}
      </Formik>
      <br />
      <br />
    </Container>
  )
}
