import { useCallback, useEffect, useState } from 'react'
import * as styles from './table.module.css'
import { ContactFlyout } from './components/contact-flyout/ContactFlyout'
import { Button } from '../shared/button/Button'
import { Icon } from '../shared/icons/Icons'
import {
  PhoneType,
  type Contact,
  useUpdateContactMutation,
  type Company,
  ListType
} from '../../__generated__/gql-types'
import { handleUpdateContact } from './helpers/handleUpdateContact'
import { AccordionTypes } from '../call-list/company/CompanySection'
import { type TogglePopover } from './Table'
import { Text } from '../shared/typography'
import { FuzeLink } from '../shared/fuze-link/FuzeLink'
import { isDevEnvironment } from '~/src/helpers/environment'
import { ClipboardCopy } from '../shared/clipboardCopy/ClipboardCopy'
import { useAuth } from '~/src/auth/hooks/useAuth'
import { ContactsDupesModal } from '../call-list/ContactsDupesModal/ContactsDupesModal'

const bullhornLogo = new URL(
  '../shared/assets/bullhorn_logo.png',
  import.meta.url
)
const LinkComponent = isDevEnvironment() ? FuzeLink : 'a'

interface TableRowProps {
  row: Contact
  setShowAddContactForm?: () => void
  togglePopover: TogglePopover
  contactCategory: string
  isReadOnly: boolean
  companyObject: Company
  expandAllContactPhones?: boolean
  listType: ListType
  setEditingContact: (contact: Contact) => void
  setContactDialogOpen: (bool: boolean) => void
  numberOfRows: number
  rowIndex: number
}

export const TableRow: React.FC<TableRowProps> = ({
  row,
  togglePopover,
  contactCategory,
  isReadOnly,
  companyObject,
  expandAllContactPhones = false,
  listType,
  setEditingContact,
  setContactDialogOpen,
  numberOfRows,
  rowIndex
}): JSX.Element => {
  const notesChartLimit = 35
  const openEditContactForm = (): void => {
    setContactDialogOpen(true)
    setEditingContact(row)
  }

  const [expandContactPhones, setExpandContactPhones] = useState<boolean>(
    expandAllContactPhones
  )

  const [showMore, setShowMore] = useState<boolean>(false)

  const showMoreToggle = (): void => {
    setShowMore((prev) => !prev)
  }

  const [updateContactFn, { error }] = useUpdateContactMutation()

  type PhoneTypes = PhoneType.Direct | PhoneType.Mobile | PhoneType.Other

  interface SelectedPhone {
    number: string
    type: PhoneTypes
  }

  const getSelectedPhone = useCallback((): SelectedPhone => {
    const hasMobilePhone = row.phone_mobile != null && row.phone_mobile !== ''
    const hasDirectPhone = row.phone_direct != null && row.phone_direct !== ''
    const hasOtherPhone = row.phone_other != null && row.phone_other !== ''
    const hasPreferredPhone = row.preferred_phone !== null
    let selectedPhone

    const direct: SelectedPhone = {
      number: row?.phone_direct as string,
      type: PhoneType.Direct
    }
    const mobile: SelectedPhone = {
      number: row.phone_mobile as string,
      type: PhoneType.Mobile
    }

    const other: SelectedPhone = {
      number: row.phone_other as string,
      type: PhoneType.Other
    }

    if (hasPreferredPhone) {
      const preferredPhone = row.preferred_phone
      switch (preferredPhone) {
        case PhoneType.Mobile:
          selectedPhone = mobile
          break
        case PhoneType.Other:
          selectedPhone = other
          break
        case PhoneType.Direct:
        default:
          selectedPhone = direct
      }
    } else if (hasMobilePhone) {
      selectedPhone = mobile
    } else if (hasDirectPhone) {
      selectedPhone = direct
    } else if (hasOtherPhone) {
      selectedPhone = other
    } else {
      selectedPhone = direct
    }

    return selectedPhone
  }, [row])

  const [selectedPhone, setSelectedPhone] = useState<SelectedPhone>(
    getSelectedPhone()
  )

  /* Reset selected phone when row changes */
  useEffect(() => {
    setSelectedPhone(getSelectedPhone())
  }, [row, getSelectedPhone])

  useEffect(() => {
    setExpandContactPhones(expandAllContactPhones)
  }, [expandAllContactPhones])

  const { id } = useAuth()

  const handlePhoneLinkClick = (number: string, type: PhoneType): void => {
    setSelectedPhone({
      number,
      type
    })
    if (isReadOnly) return
    handleUpdateContact(updateContactFn, { preferred_phone: type }, row.id, id)
      .then(() => {
        togglePopover('success', 'Preferred Phone Updated...')
      })
      .catch((err) => {
        togglePopover('error', error?.message ?? 'Error...')
        console.log(err)
      })
  }

  const showBullhorn = typeof row?.bullhorn_id === 'number'

  const bullhornContactUrl = (id: number): string => {
    return `https://cls42.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=ClientContact&id=${id}`
  }

  const [duplicateContactDialogOpen, setDuplicateContactDialogOpen] =
    useState(false)

  return (
    <tr
      key={row.id}
      className={
        row.referral && listType === ListType.Active ? styles.referral : ''
      }
    >
      <td className={showBullhorn ? styles['has-logo'] : ''}>
        <div>
          <div className={styles.logo}>
            {showBullhorn && (
              <Text
                style='link'
                as='a'
                linkNewTab={true}
                href={bullhornContactUrl(row?.bullhorn_id as number)}
              >
                <img
                  src={bullhornLogo.toString()}
                  className={styles['bullhorn-logo']}
                  alt='Bullhorn'
                />
              </Text>
            )}
          </div>
          <div className={styles.name}>
            <p>
              {row.first_name} {row.last_name}
            </p>
            <Text as='p' style='role' weight='regular'>
              {row.title}
            </Text>
          </div>
          {listType === ListType.Active &&
            !isReadOnly &&
            row.duplicates &&
            (row.duplicates.amcl >= 1 || row.duplicates.bullhorn >= 1) && (
              <>
                <ContactsDupesModal
                  isOpen={duplicateContactDialogOpen}
                  setIsOpen={setDuplicateContactDialogOpen}
                  yourContactData={row}
                  yourCompanyData={companyObject}
                />
                <Button
                  as='button'
                  style='icon-only'
                  onClick={() => {
                    setDuplicateContactDialogOpen(true)
                  }}
                  id={`show-contacts-duplicates-${row.id}`}
                >
                  <Icon
                    symbol='PeopleOutlinedIcon'
                    color='alku-red'
                    size='lg'
                    space='right'
                  />
                </Button>
              </>
            )}
        </div>
      </td>
      <td>
        <div className={styles.phoneRow}>
          <div>
            {!expandContactPhones ? (
              <div className={styles.phoneFlex}>
                {selectedPhone.type === PhoneType.Direct
                  ? 'D:'
                  : selectedPhone.type === PhoneType.Mobile
                  ? 'M:'
                  : 'O:'}{' '}
                <LinkComponent
                  className={styles.phone}
                  href={'tel:' + selectedPhone.number}
                >
                  {selectedPhone.number}
                </LinkComponent>
                {selectedPhone.number && (
                  <ClipboardCopy
                    id={`${row.id}-${selectedPhone.type}-phone`}
                    text={selectedPhone.number}
                    label='copy selected phone'
                  />
                )}
              </div>
            ) : (
              <div>
                <div className={styles.phoneFlex}>
                  D:{' '}
                  <LinkComponent
                    className={styles.phone}
                    href={`tel:  ${row.phone_direct as string}`}
                    onClick={() => {
                      handlePhoneLinkClick(
                        row.phone_direct as string,
                        PhoneType.Direct
                      )
                    }}
                  >
                    {row.phone_direct}
                  </LinkComponent>
                  {row.phone_direct && (
                    <ClipboardCopy
                      id={`${row.id}-direct-phone`}
                      text={row.phone_direct}
                      label='copy direct phone'
                    />
                  )}
                </div>
                <div className={styles.phoneFlex}>
                  M:{' '}
                  <LinkComponent
                    className={styles.phone}
                    href={`tel:  ${row.phone_mobile as string}`}
                    onClick={() => {
                      handlePhoneLinkClick(
                        row.phone_mobile as string,
                        PhoneType.Mobile
                      )
                    }}
                  >
                    {row.phone_mobile}
                  </LinkComponent>
                  {row.phone_mobile && (
                    <ClipboardCopy
                      id={`${row.id}-mobile-phone`}
                      text={row.phone_mobile}
                      label='copy mobile phone'
                    />
                  )}
                </div>
                <div className={styles.phoneFlex}>
                  O:{' '}
                  <LinkComponent
                    className={styles.phone}
                    href={`tel:  ${row.phone_other as string}`}
                    onClick={() => {
                      handlePhoneLinkClick(
                        row.phone_other as string,
                        PhoneType.Other
                      )
                    }}
                  >
                    {row.phone_other}
                  </LinkComponent>
                  {row.phone_other && (
                    <ClipboardCopy
                      id={`${row.id}-other-phone`}
                      text={row.phone_other}
                      label='copy other phone'
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <Button
            as='button'
            ariaLabel={`${
              expandContactPhones ? 'collapse' : 'expand'
            } contact row phones`}
            style='icon-only'
            onClick={() => {
              setExpandContactPhones(!expandContactPhones)
            }}
            id={`expand-collapse-${row.id}`}
          >
            <Icon
              symbol={
                expandContactPhones
                  ? 'ExpandLessOutlinedIcon'
                  : 'ExpandMoreOutlinedIcon'
              }
              color='bright-blue'
              size='md'
              space='left'
            />
          </Button>
        </div>
      </td>
      <td>{row.address_state}</td>
      <td>{row.email}</td>
      <td>
        <div className={styles.notes}>
          {typeof row?.notes === 'string' &&
            row.notes.length <= notesChartLimit && <>{row.notes}</>}
          {typeof row?.notes === 'string' &&
            row.notes.length > notesChartLimit && (
              <>
                {showMore
                  ? row.notes
                  : row.notes.slice(0, notesChartLimit) + '...'}{' '}
                <Button
                  as='button'
                  style='link'
                  onClick={showMoreToggle}
                  id={`${row.id}-show-more-less`}
                >
                  {showMore ? 'Show less' : 'Show more'}
                </Button>
              </>
            )}
        </div>
      </td>
      <td>
        {!isReadOnly && contactCategory !== AccordionTypes.DBAContacts && (
          <ContactFlyout
            listType={listType}
            openEditContactForm={openEditContactForm}
            contact={row}
            togglePopover={togglePopover}
            contactCategory={contactCategory}
            companyObject={companyObject}
            position={
              numberOfRows > 6 && rowIndex > numberOfRows - 5 ? 'bottom' : 'top'
            }
          />
        )}
      </td>
    </tr>
  )
}
