import * as Yup from 'yup'

import {
  ContactSource,
  InternOrigin,
  PhoneType
} from '~/src/__generated__/gql-types'
import { type SelectValue } from '~/src/components/shared/select/types'

export const DBAFormSchema = Yup.object({
  company_bullhorn_id: Yup.number()
    .transform((val) => Number(val))
    .required()
    .label('Company'),
  company_name: Yup.string(),
  first_name: Yup.string().required().label('First Name'),
  last_name: Yup.string().required().label('Last Name'),
  email: Yup.string().email().required().label('Email').trim(),
  phone_direct: Yup.string().required().label('Direct Phone Number'),
  phone_mobile: Yup.string().label('Mobile Phone Number').nullable(),
  phone_other: Yup.string().label('Other Phone Number').nullable(),
  title: Yup.string().required().label('Title'),
  division_bullhorn_id: Yup.number()
    .required()
    .transform((val) => (isNaN(Number(val)) ? undefined : Number(val)))
    .label('Division'),
  intern_origin: Yup.string()
    .required()
    .oneOf(['YES', 'NO'])
    .label('Intern Origin'),
  SUPP: Yup.string().required(),
  source: Yup.string().required().label('Source'),
  tradeshow: Yup.string().when('source', {
    is: ContactSource.Tradeshow as any,
    then: (DBAFormSchema) =>
      Yup.string().required('Tradeshow field is required'),
    otherwise: (DBAFormSchema) => Yup.string().nullable()
  }),
  isNotesRequired: Yup.boolean().default(true),
  notes: Yup.string().when('isNotesRequired', {
    is: true,
    then: (DBAFormSchema) => Yup.string().required('Notes are required'),
    otherwise: (DBAFormSchema) => Yup.string().notRequired()
  }),
  isActionRequired: Yup.boolean().default(true),
  action: Yup.string().when('isActionRequired', {
    is: true,
    then: (DBAFormSchema) => Yup.string().required('Action is required'),
    otherwise: (DBAFormSchema) => Yup.string().notRequired()
  }),
  isEmailsSuscriptionsRequired: Yup.boolean().default(true),
  email_subscriptions: Yup.array()
    .of(
      Yup.string().email('Invalid email format').required('Email is required')
    )
    .when('isEmailsSuscriptionsRequired', {
      is: true,
      then: (DBAFormSchema) =>
        Yup.array()
          .of(
            Yup.string()
              .email('Invalid email format')
              .required('Email is required')
          )
          .required('Email subscriptions are required')
          .min(1, 'At least one email is required'),
      otherwise: (DBAFormSchema) => Yup.array().notRequired()
    })
})

export const phoneTypeOptions: Record<PhoneType, SelectValue<PhoneType>> = {
  [PhoneType.Direct]: { label: 'Direct', value: PhoneType.Direct },
  [PhoneType.Mobile]: { label: 'Mobile', value: PhoneType.Mobile },
  [PhoneType.Other]: { label: 'Other', value: PhoneType.Other }
}

export const internOriginOptions: Record<
  InternOrigin,
  SelectValue<InternOrigin>
> = {
  [InternOrigin.Yes]: { label: 'Yes', value: InternOrigin.Yes },
  [InternOrigin.No]: { label: 'No', value: InternOrigin.No }
}

export const getSelectedOptions = (
  options: Array<SelectValue<string>> | undefined,
  selected: string[] | undefined
): Array<SelectValue<string>> | undefined => {
  if (selected === undefined) return undefined
  if (selected) {
    const selectedArr = selected.map((val) => {
      return { label: val, value: val }
    })
    return selectedArr
  }

  if (options) return options
}
