import { type FC } from 'react'

/* types */
import {
  type Company,
  type Lead,
  LeadType,
  ListType
} from '~/src/__generated__/gql-types'

/* components */
import { Button } from '../../shared/button/Button'
import { Icon } from '../../shared/icons/Icons'
import { Text } from '../../shared/typography'

/* styles */
import * as styles from './company.module.css'
import { isDevEnvironment } from '~/src/helpers/environment'
import { FuzeLink } from '../../shared/fuze-link/FuzeLink'

interface CompanySummaryProps {
  data: Company
  isReadOnly: boolean
  listType: ListType
  handleContactAccordionToggle: () => void
  leads?: Lead[]
}

function getLeadTypeName(type: string): string {
  let typeName = ''
  switch (type) {
    case LeadType.Hot:
      typeName = 'Hot Lead'
      break
    case LeadType.Yellow:
      typeName = 'Yellow Lead'
      break
    case LeadType.MarketplaceInfo:
      typeName = 'Marketplace Information'
      break
    case LeadType.Reference:
      typeName = 'Reference Lead'
      break
    default:
      typeName = type
  }
  return typeName
}
export const CompanySummary: FC<CompanySummaryProps> = ({
  data,
  isReadOnly,
  listType,
  leads,
  handleContactAccordionToggle
}) => {
  const LinkComponent = isDevEnvironment() ? FuzeLink : 'a'

  const companyCreatedDate = data.created_ts
    ? new Date(data.created_ts).toLocaleDateString('en-US')
    : null

  const placeHolderTextProps = {
    as: 'p',
    style: 'detail',
    color: 'grey-8'
  }

  const lead =
    listType === ListType.Leads
      ? leads?.find((lead) => lead?.ref_company === data.id)
      : null

  return (
    <>
      <div className={styles.companySummary}>
        <div className={styles.date}>
          <Text style='detail'>
            Date Added: {companyCreatedDate ?? 'Not Recorded'}
          </Text>
        </div>
        <div className={styles.details}>
          {typeof data?.address1 === 'string' && data.address1.length > 0 ? (
            <div className={styles.address}>
              <Text as='p' style='detail' color='black'>
                {data?.address1}, {data?.address2} <br /> {data?.city},{' '}
                {data?.state} {data?.postal}
                {data?.timezone && (
                  <span className={styles.timezone}>{data?.timezone}</span>
                )}
              </Text>
            </div>
          ) : (
            <div className={styles.companyPlaceholder}>
              <Text {...placeHolderTextProps}>Address not added</Text>
              <Text {...placeHolderTextProps}>
                {data?.state} {data?.postal}
                {data?.timezone && (
                  <span className={styles.timezone}>{data?.timezone}</span>
                )}
              </Text>
            </div>
          )}
          <div className={styles.phoneContainer}>
            <div className={styles.phone}>
              {typeof data?.phone === 'string' && data.phone.length > 0 ? (
                <Text as='p' style='detail' color='black'>
                  <Text as='span' style='detail' weight='bold'>
                    HQ:
                  </Text>
                  <LinkComponent
                    href={`tel:${data?.phone ?? ''}`}
                    color='dark-blue'
                  >
                    <span className={styles.hqlink}>{data?.phone}</span>
                  </LinkComponent>
                </Text>
              ) : (
                <div className={styles.companyPlaceholder}>
                  <Text {...placeHolderTextProps}>Phone not added</Text>
                </div>
              )}
            </div>
            <div className={styles.phone}>
              {typeof data?.phone2 === 'string' &&
              data.phone2.trim().length > 0 ? (
                <Text as='p' style='detail' color='black'>
                  <Text as='span' style='detail' weight='bold'>
                    O:
                  </Text>
                  <LinkComponent
                    href={`tel:${String(data?.phone2)}`}
                    color='dark-blue'
                  >
                    <span className={styles.hqlink}>{data?.phone2}</span>
                  </LinkComponent>
                </Text>
              ) : (
                <div className={styles.companyPlaceholder}>
                  <Text {...placeHolderTextProps}> Other Phone not added</Text>
                </div>
              )}
            </div>
          </div>
          {listType === ListType.Leads && (
            <>
              <div className={styles.leadContainer}>
                <div className={styles.leadType}>
                  <Text style='detail'>
                    <Text as='span' style='detail' weight='bold'>
                      Date assigned:
                    </Text>{' '}
                    {lead?.created_ts
                      ? new Date(lead?.created_ts).toLocaleDateString('en-US')
                      : 'Not Recorded'}
                  </Text>
                </div>
                <div className={styles.recruiter}>
                  <Text style='detail'>
                    <Text as='span' style='detail' weight='bold'>
                      Recruiter:
                    </Text>{' '}
                    {lead?.recruiter_name ?? 'Not Recorded'}
                  </Text>
                </div>
              </div>
              <div className={styles.leadContainer}>
                <div className={styles.leadType}>
                  <Text style='detail'>
                    <Text as='span' style='detail' weight='bold'>
                      Hiring Manager:
                    </Text>{' '}
                    {lead?.hiring_manager_name ?? 'Not Recorded'}
                  </Text>
                </div>
                <div className={styles.recruiter}>
                  <Text style='detail'>
                    <Text as='span' style='detail' weight='bold'>
                      Manager Phone:
                    </Text>{' '}
                    {lead?.manager_phone ?? 'Phone not added'}
                  </Text>
                </div>
              </div>
              <div className={styles.leadType}>
                <Text as='span' style='detail' weight='bold'>
                  Lead Type:{' '}
                </Text>
                <div className={styles.leadIcon}>
                  {lead?.type === LeadType.Hot && (
                    <Icon
                      symbol='LocalFireDepartmentIcon'
                      color='hot-lead'
                      size='md'
                    />
                  )}
                  {lead?.type === LeadType.MarketplaceInfo && (
                    <Icon
                      symbol='StoreOutlinedIcon'
                      color='marketplace-lead'
                      size='md'
                    />
                  )}
                  {lead?.type === LeadType.Yellow && (
                    <Icon
                      symbol='MyLocationOutlinedIcon'
                      color='yellow-lead'
                      size='md'
                    />
                  )}
                  {lead?.type === LeadType.Reference && (
                    <Icon
                      symbol='ConnectWithoutContactOutlinedIcon'
                      color='reference-lead'
                      size='md'
                    />
                  )}
                </div>
                <Text style='detail'>
                  {getLeadTypeName(lead?.type) ?? 'Not Recorded'}
                </Text>
              </div>
            </>
          )}
        </div>
        <Text style='detail'>
          {typeof data?.notes === 'string' && data.notes.length > 0 ? (
            <div className={styles.notes}>{data?.notes}</div>
          ) : (
            <div className={styles.companyPlaceholder}>
              <Text {...placeHolderTextProps}>Notes not added</Text>
            </div>
          )}
        </Text>
      </div>
      {!isReadOnly && (
        <div className={styles.companyAddContact}>
          <Button
            id='add-contact-button'
            as='button'
            type='button'
            onClick={() => {
              handleContactAccordionToggle()
            }}
            style='secondary'
          >
            <Icon
              symbol='AddOutlinedIcon'
              color='alku-red'
              size='lg'
              space='right'
            />
            Add Contact
          </Button>
        </div>
      )}
    </>
  )
}
