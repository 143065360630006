import { useAuth } from '../auth/hooks/useAuth'
import { useGlobalState } from '../auth/hooks/useGlobal'
import { Container } from '../components/shared/container/Container'
import { Tile } from '../components/shared/tile/Tile'
import { Heading } from '../components/shared/typography'
import * as sharedStyles from '../styles.module.css'
import {
  type CallListSummary,
  useGetActiveListSummaryQuery,
  useGetLeadsListSummaryQuery,
  ListType,
  useGetNotCallingListSummaryQuery,
  useGetFavoriteSummariesQuery
} from '../__generated__/gql-types'
import { AccordionTypes } from '../components/call-list/company/CompanySection'
import { FlyoutIconButton } from '../components/call-list/FlyoutIconButton/FlyoutIconButton'
import { FlyoutListItem } from '../components/shared/flyout/FlyoutListItem'
import { useState } from 'react'
import {
  ArchiveCallListModal,
  ExportCallListModal
} from '../components/call-list/ExportCallListModal/ExportCallListModal'
import { TileType } from '../components/shared/select/types'

export const MyLists = (): JSX.Element => {
  const { id } = useAuth()
  const { setGlobalState } = useGlobalState()

  type PartialCallListSummary = Omit<
    CallListSummary,
    'id' | 'user_ids' | 'user_division'
  >

  const [archiveDialog, setArchiveDialog] = useState({
    open: false,
    type: ListType.Active
  })

  const [exportDialog, setExportDialog] = useState({
    open: false,
    type: ListType.Active
  })

  const setArchiveDialogOpen = (status: boolean): void => {
    setArchiveDialog((prev) => ({ type: prev.type, open: status }))
  }

  const setExportDialogOpen = (status: boolean): void => {
    setExportDialog((prev) => ({ type: prev.type, open: status }))
  }

  /* Get active my list data */
  const {
    data: myListData,
    error: myListError,
    loading: myListLoading
  } = useGetActiveListSummaryQuery({
    variables: {
      userId: id
    }
  })

  /* Get my people list data */
  const {
    data: myFavoritesData,
    error: myFavoritesError,
    loading: myFavoritesLoading
  } = useGetFavoriteSummariesQuery()

  const favoriteData = myFavoritesData?.getFavoriteSummaries

  /* Get leads list data */
  const {
    data: leadsList,
    error: leadsListError,
    loading: leadsListLoading
  } = useGetLeadsListSummaryQuery({
    variables: {
      userId: id
    }
  })

  /* Get leads not callin list data */
  const {
    data: notCallingList,
    error: notCallingListError,
    loading: notCallingListLoading
  } = useGetNotCallingListSummaryQuery({
    variables: {
      userId: id
    }
  })

  if (myListError != null) console.log('error:', myListError)
  if (myFavoritesError != null) console.log('data:', myFavoritesError)
  if (leadsListError != null) console.log('data:', leadsListError)
  if (notCallingListError != null) console.log('data:', notCallingListError)

  return (
    <>
      <Container>
        <Heading as='h1' level='one'>
          My Lists
        </Heading>
        <div className={sharedStyles.section}>
          {!myListLoading && (
            <div className={sharedStyles.listContainer}>
              <Tile
                type='my-list'
                tileType={TileType.ACTIVE}
                data={
                  myListData?.getActiveList.summary as PartialCallListSummary
                }
                id={id}
                actions={
                  <FlyoutIconButton
                    id='more-button'
                    label='More Options Menu for Your Call List'
                    buttonProps={{ style: 'icon-only' }}
                    iconProps={{ symbol: 'MoreVertOutlinedIcon' }}
                    flyoutWidth='md'
                    flyoutChildren={[
                      <FlyoutListItem
                        key='more-export'
                        buttonText='Export List'
                        iconSymbol='VerticalAlignBottomIcon'
                        buttonProps={{ id: 'open-export-dialog-button' }}
                        onClick={() => {
                          setExportDialog({
                            open: true,
                            type: ListType.Active
                          })
                        }}
                      />,
                      <FlyoutListItem
                        key='more-delete'
                        buttonText='Delete List'
                        iconSymbol='DeleteOutlinedIcon'
                        buttonProps={{ id: 'open-delete-dialog-button' }}
                        onClick={() => {
                          setArchiveDialog({
                            open: true,
                            type: ListType.Active
                          })
                        }}
                      />
                    ]}
                  />
                }
              />
              <Tile
                type='my-list'
                tileType={TileType.DBA}
                data={
                  myListData?.getActiveList.summary as Partial<CallListSummary>
                }
                id={id}
                onClick={() => {
                  setGlobalState({
                    defaultOpenContactCategory: [AccordionTypes.DBAContacts]
                  })
                }}
              />
              <Tile
                type='my-list'
                tileType={TileType.DISQUALIFIED}
                data={
                  myListData?.getActiveList.summary as Partial<CallListSummary>
                }
                id={id}
                onClick={() => {
                  setGlobalState({
                    defaultOpenContactCategory: [
                      AccordionTypes.DisqualifiedContacts
                    ]
                  })
                }}
              />

              {!leadsListLoading && (
                <Tile
                  type='my-list'
                  tileType={TileType.LEADS}
                  key='leads-list-tile'
                  data={
                    leadsList?.getLeadsList.summary as Partial<CallListSummary>
                  }
                  count={leadsList?.getLeadsList?.new_companies ?? 0}
                  id={`${id}/leads`}
                  actions={
                    <FlyoutIconButton
                      id='more-button'
                      label='More Options Menu for Your Call List'
                      buttonProps={{ style: 'icon-only' }}
                      iconProps={{ symbol: 'MoreVertOutlinedIcon' }}
                      flyoutWidth='md'
                      flyoutChildren={[
                        <FlyoutListItem
                          key='more-export'
                          buttonText='Export List'
                          iconSymbol='VerticalAlignBottomIcon'
                          buttonProps={{ id: 'open-export-dialog-button' }}
                          onClick={() => {
                            setExportDialog({
                              open: true,
                              type: ListType.Leads
                            })
                          }}
                        />,
                        <FlyoutListItem
                          key='more-delete'
                          buttonText='Delete List'
                          iconSymbol='DeleteOutlinedIcon'
                          buttonProps={{ id: 'open-delete-dialog-button' }}
                          onClick={() => {
                            setArchiveDialog({
                              open: true,
                              type: ListType.Leads
                            })
                          }}
                        />
                      ]}
                    />
                  }
                />
              )}

              {!notCallingListLoading && (
                <Tile
                  type='my-list'
                  tileType={TileType.DNC}
                  key='dnc-list-tile'
                  data={
                    notCallingList?.getNotCallingList
                      .summary as Partial<CallListSummary>
                  }
                  id={`${id}/not-calling`}
                  actions={
                    <FlyoutIconButton
                      id='more-button'
                      label='More Options Menu for Your Call List'
                      buttonProps={{ style: 'icon-only' }}
                      iconProps={{ symbol: 'MoreVertOutlinedIcon' }}
                      flyoutWidth='md'
                      flyoutChildren={[
                        <FlyoutListItem
                          key='more-export'
                          buttonText='Export List'
                          iconSymbol='VerticalAlignBottomIcon'
                          buttonProps={{ id: 'open-export-dialog-button' }}
                          onClick={() => {
                            setExportDialog({
                              open: true,
                              type: ListType.NotCalling
                            })
                          }}
                        />,
                        <FlyoutListItem
                          key='more-delete'
                          buttonText='Delete List'
                          iconSymbol='DeleteOutlinedIcon'
                          buttonProps={{ id: 'open-delete-dialog-button' }}
                          onClick={() => {
                            setArchiveDialog({
                              open: true,
                              type: ListType.NotCalling
                            })
                          }}
                        />
                      ]}
                    />
                  }
                />
              )}
            </div>
          )}
          {myListError != null && 'An error occurred: see browser console'}
        </div>
        {archiveDialog.open && (
          <ArchiveCallListModal
            listType={archiveDialog.type}
            isOpen={archiveDialog.open}
            setIsOpen={setArchiveDialogOpen}
            callListUserId={id}
          />
        )}
        {exportDialog.open && (
          <ExportCallListModal
            listType={exportDialog.type}
            isOpen={exportDialog.open}
            setIsOpen={setExportDialogOpen}
          />
        )}
      </Container>

      {favoriteData && favoriteData.length > 0 && (
        <Container>
          {favoriteData && favoriteData.length > 0 && (
            <Heading as='h1' level='one'>
              My People’s Lists
            </Heading>
          )}
          <div className={sharedStyles.section}>
            <div className={sharedStyles.listContainer} id='my-people-list'>
              {typeof myFavoritesLoading === 'boolean' &&
                !myFavoritesLoading &&
                favoriteData?.map(
                  (list) =>
                    list != null && (
                      <Tile
                        tileType={TileType.DEFAULT}
                        type='default'
                        key={`call-list-tile-${list.user_ids.id}`}
                        data={list as Partial<CallListSummary>}
                        id={list.user_ids.id}
                      />
                    )
                )}
            </div>
          </div>
        </Container>
      )}
    </>
  )
}
