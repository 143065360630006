import {
  useUpdateProfileFavoritesMutation,
  type UpdateProfileFavoritesMutationFn,
  type UpdateProfileInput,
  GetFavoriteSummariesDocument
} from '../../../__generated__/gql-types'
import { useAuth } from '../../../auth/hooks/useAuth'
import { Icon } from '../icons/Icons'
import { Tooltip } from '../tooltip/Tooltip'

export const handleUpdateProfile = async (
  updateProfileFn: UpdateProfileFavoritesMutationFn,
  updatedProfile: UpdateProfileInput
): Promise<boolean> => {
  try {
    await updateProfileFn({
      variables: {
        favorites: updatedProfile.favorites
      },
      refetchQueries: [
        {
          query: GetFavoriteSummariesDocument
        }
      ]
    })
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export const Favorite = ({ id }: { id: string }): JSX.Element => {
  const { favorites } = useAuth()
  const [updateProfileFn] = useUpdateProfileFavoritesMutation()

  const triggerGtmEvent = (eventName: string): void => {
    window.dataLayer.push({
      event: eventName
    })
  }

  const currentFavorites = (favorites?.slice() as string[]) ?? ['']
  const isFavorited = currentFavorites.includes(id)

  const handleUpdateFavorites = (): void => {
    const favoritesList = currentFavorites
    const clickedId = id
    let eventType = ''

    if (!favoritesList.includes(clickedId)) {
      favoritesList.push(clickedId)
      eventType = 'ga-favorite'
    } else {
      favoritesList.splice(favoritesList.indexOf(clickedId), 1)
      eventType = 'ga-unfavorite'
    }

    handleUpdateProfile(updateProfileFn, {
      favorites: favoritesList
    })
      .then(() => {
        triggerGtmEvent(eventType)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <Tooltip
      text={
        isFavorited ? "Remove from My People's List" : "Add to My People's List"
      }
      width={isFavorited ? 'lg' : 'md'}
      tooltipId={
        isFavorited ? `favorite-tooltip-${id}` : `unfavorite-tooltip-${id}`
      }
      position='center'
      id={`favorite-button-${id}`}
      onClick={() => {
        handleUpdateFavorites()
      }}
      isFavorited={isFavorited}
    >
      <Icon
        symbol={isFavorited ? 'StarIcon' : 'StarBorderOutlinedIcon'}
        color='icon-favorite'
        size='lg'
        space='left'
      />
    </Tooltip>
  )
}
