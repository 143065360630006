import * as styles from './tile.module.css'
import { type ReactNode, useMemo } from 'react'
import { Heading, Text } from '../typography'
import { Avatar } from '../avatar/Avatar'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { type CallListSummary } from '../../../__generated__/gql-types'
import { Favorite } from '../favorite/Favorite'
import { TileType } from '../select/types'

/**
 * Tile
 * @param {string} type - default or my list are the supported variations
 * @param {object} data - call list summary data
 * @param {string} tileType- required if my-list is set as types
 */

interface CommonProps {
  data: Partial<CallListSummary>
  id: string
  onClick?: () => void
  actions?: ReactNode
}

interface DefaultTileProps extends CommonProps {
  type: 'default'
  tileType: TileType
}

interface MyListTileProps extends CommonProps {
  type: 'my-list'
  tileType: TileType
}

type ConditionalProps = MyListTileProps | DefaultTileProps

type TileProps = ConditionalProps & {
  title?: string
  summary?: string
  count?: number
}

interface MyListProps {
  title?: string
  summary?: JSX.Element
}

export const Tile = ({
  type = 'default',
  data,
  id,
  tileType,
  onClick,
  actions,
  count = 0
}: TileProps): JSX.Element => {
  const triggerGtmEvent = (): void => {
    let eventType = ''
    if (tileType === TileType.ACTIVE) eventType = 'Active Contacts'
    if (tileType === TileType.DBA) eventType = 'DBA Contacts'
    if (tileType === TileType.DISQUALIFIED) eventType = 'Disqualified Contacts'
    if (tileType === TileType.DNC) eventType = 'Do Not Call List'
    if (tileType === TileType.LEADS) eventType = 'Lead List'
    if (tileType === TileType.DEFAULT && data.first_name && data.last_name)
      eventType = `${data.first_name} ${data.last_name}`
    window.dataLayer.push({
      event: 'ga-open-list',
      list: eventType
    })

    if (typeof onClick === 'function') onClick()
  }

  const myListProps: MyListProps = useMemo(() => {
    const props: MyListProps = {}
    if (data !== null && data !== undefined) {
      // TODO: update once 705 is in with new queries to just use default list counts
      const {
        companies_count,
        contacts_count,
        first_name,
        last_name,
        dba_count,
        disqualified_count
      } = data
      switch (tileType) {
        case TileType.ACTIVE:
          props.title = `${first_name ?? ''} ${
            last_name ?? ''
          } - Active Call List`
          props.summary = (
            <Text style='detail' as='span'>
              <span className={styles['count-item']}>
                Active:{' '}
                <Text style='detail' as='span' weight='semibold'>
                  {contacts_count ?? 0}
                </Text>
              </span>
              <span className={styles['count-item']}>
                DBA:{' '}
                <Text style='detail' as='span' weight='semibold'>
                  {dba_count ?? 0}
                </Text>
              </span>
              <br />
              <span className={styles['count-item']}>
                Disqualified:{' '}
                <Text style='detail' as='span' weight='semibold'>
                  {data.disqualified_count ?? '0'}
                </Text>
              </span>
            </Text>
          )

          break
        case TileType.DISQUALIFIED:
          props.title = 'Disqualified Contacts'
          props.summary = (
            <Text style='detail' as='span'>
              Disqualified:{' '}
              <Text style='detail' as='span' weight='semibold'>
                {disqualified_count ?? 0}
              </Text>
            </Text>
          )
          break
        case TileType.DBA:
          props.title = 'DBA Contacts'
          props.summary = (
            <Text style='detail' as='span'>
              DBA:{' '}
              <Text style='detail' as='span' weight='semibold'>
                {dba_count ?? 0}
              </Text>
            </Text>
          )
          break
        case TileType.DNC:
          props.title = `${first_name ?? ''} ${
            last_name ?? ''
          } - Not Calling List`
          props.summary = (
            <Text style='detail' as='span'>
              Active:{' '}
              <Text style='detail' as='span' weight='semibold'>
                {contacts_count ?? 0}
              </Text>{' '}
              DBA:{' '}
              <Text style='detail' as='span' weight='semibold'>
                {dba_count ?? 0}
              </Text>
              <br />
              Disqualified:{' '}
              <Text style='detail' as='span' weight='semibold'>
                {data.disqualified_count ?? '0'}
              </Text>
            </Text>
          )
          break
        case TileType.LEADS:
          props.title = 'Leads List'
          props.summary = (
            <Text style='detail' as='span'>
              Companies:{' '}
              <Text style='detail' as='span' weight='semibold'>
                {companies_count ?? 0}
              </Text>{' '}
              <br />
              Contacts:{' '}
              <Text style='detail' as='span' weight='semibold'>
                {contacts_count ?? 0}
              </Text>
            </Text>
          )
          break
        default:
          props.title = `${first_name ?? ''} ${last_name ?? ''}`
          props.summary = (
            <Text style='detail' as='span'>
              Active:{' '}
              <Text style='detail' as='span' weight='semibold'>
                {contacts_count ?? 0}
              </Text>{' '}
              DBA:{' '}
              <Text style='detail' as='span' weight='semibold'>
                {dba_count ?? 0}
              </Text>
              <br />
              Disqualified:{' '}
              <Text style='detail' as='span' weight='semibold'>
                {data.disqualified_count ?? '0'}
              </Text>
            </Text>
          )
          break
      }
    }
    return props
  }, [data, tileType])

  const initials = `${(data?.first_name?.charAt(0) ?? '').toUpperCase()}${(
    data?.last_name?.charAt(0) ?? ''
  ).toUpperCase()}`

  return (
    <>
      <div
        className={classNames(styles.container, styles[type], styles[tileType])}
      >
        <Link
          to={`/${id}`}
          onClick={triggerGtmEvent}
          className={classNames(styles.tile, styles[type])}
        >
          {tileType === TileType.LEADS && count > 0 && (
            <div
              className={classNames(
                styles.countBadge,
                actions ? styles.badgeWithFlyout : ''
              )}
            >
              {count}
            </div>
          )}
          <div className={styles.title}>
            <Heading as='h2' level='two' color='black'>
              {myListProps?.title}
            </Heading>
          </div>
          <div className={styles.details}>
            <Avatar initials={initials} size='md' />
            <Text as='div' style='detail' color='dark-blue'>
              <div className={styles.aggregates}>
                {type === 'default' ? (
                  <Text as='p' style='detail' weight='bold' color='dark-blue'>
                    {data.user_division ?? 'Unknown'}
                  </Text>
                ) : (
                  ''
                )}
                <span>{myListProps?.summary}</span>
              </div>
            </Text>
          </div>
        </Link>
        {type !== 'my-list' ? (
          <div className={styles.action}>
            <Favorite id={id} />
          </div>
        ) : null}
        {actions ? <div className={styles.action}>{actions}</div> : null}
      </div>
    </>
  )
}
