import * as styles from './icons.module.css'
import * as sharedStyles from '../../../styles.module.css'
import classNames from 'classnames'
import * as animationStyles from '../../../animation.module.css'
import { type IconOptions, iconOptions } from './symbols'

/**
 * Icons
 * @param {string} symbol - see IconOptions for list of imported symbols
 * @param {string} size - icon size, currently supports xs, sm, md and lg (see if we can manage w/o custom rems)
 * @param {string} color - icon color from global colors defined in base.css
 * @param {string} [space] - add default spacing to left or right or both ("center") sides of icon
 * @param {boolean} [rotate] - rotate animation for down/up arrows
 */

export interface IconProps {
  symbol: IconOptions
  color: string
  size: string
  space?: 'left' | 'right' | 'center'
  rotate?: boolean
  animation?: 'spin' | 'flip'
}

export interface SymbolProps {
  fontSize: 'inherit'
  color: 'inherit'
  symbol: IconOptions
  style: {
    height: string
    width: string
  }
}

export const IconSymbol: React.FC<SymbolProps> = (props): JSX.Element => {
  // fall back to question mark icon if symbol is undefined
  const Symbol =
    iconOptions[props.symbol] ?? iconOptions.QuestionMarkOutlinedIcon
  return <Symbol {...props} />
}

export const Icon: React.FC<IconProps> = (props): JSX.Element => {
  const {
    symbol,
    color,
    size,
    space = 'none',
    rotate = false,
    animation
  } = props
  return (
    <span
      className={classNames(
        styles.icon,
        sharedStyles[color],
        styles[size],
        styles[space],
        rotate ? styles.open : styles.closed,
        typeof animation === 'string' && animationStyles[animation],
        typeof animation === 'string' && space !== 'none' && styles.animated,
        styles['no-shrink']
      )}
      aria-hidden
    >
      <IconSymbol
        fontSize='inherit'
        color='inherit'
        symbol={symbol}
        style={{ height: '1.5em', width: '1.5em' }}
      />
    </span>
  )
}
