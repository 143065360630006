@import '../../../base.css';

/*
  Button:
  Component Styles
*/

.primary {
  min-width: 17rem;
  color: var(--white from global);
  background-color: var(--alku-red from global);
  border: none;
  padding: 1.5rem;
  font-size: 1.6rem;

  &:hover {
    background-color: #f5001e;
  }

  &:active {
    background-color: #a50014;
  }

  &:disabled {
    background-color: #dab1b6;
  }
}

.secondary {
  font-size: 1.3rem;
  font-weight: 600;
  padding: 0.8rem 1.2rem;
  min-width: 12.7rem;
  max-height: 3.8rem;

  &:hover {
    background-color: var(--white from global);
  }

  &:active {
    border-color: var(--icon-default from global);
  }

  &:disabled {
    opacity: 0.5;
  }
}

.function {
  padding: 0.4rem;
}

.secondary,
.function {
  color: var(--black from global);
  border: var(--border-add-contact from global);
  background-color: var(--grey-2 from global);

  &:hover {
    background-color: var(--white from global);
  }

  &:active {
    border-color: var(--icon-default from global);
  }

  &:disabled {
    opacity: 0.5;
  }
}

.primary,
.secondary,
.function,
.cancel {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.1rem;
  transition: 0.3s ease all;
  letter-spacing: -0.01rem;
  line-height: 1;
  font-weight: 600;
  text-align: center;
  flex-shrink: 0;

  &:focus {
    box-shadow: 0 0 1rem 0 rgba(9, 55, 124, 0.5);
  }
}

.icon-only {
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
  background-color: transparent;
  border: none;

  &:disabled,
  &[aria-disabled] {
    color: var(--disabled-check from global);

    &:hover {
      cursor: not-allowed;
    }
  }
}

.link {
  text-decoration: underline;
  background: transparent;
  padding: 0;
  border: none;
}

.cancel {
  min-width: 17rem;
  border: none;
  padding: 1.5rem;
  font-size: 1.6rem;
  background-color: var(--grey-2 from global);
  border: 0.1rem solid var(--grey-6 from global);
  color: var(--black from global);
  margin-left: 2rem;

  &:hover {
    background-color: var(--grey-7 from global);
  }
}
