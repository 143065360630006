/*
  Validation Error:
  Component Styles
*/

.container {
  color: var(--alku-red from global);
  border-radius: 0.1rem;
  padding: 0.8rem 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  text-wrap: wrap;
  text-align: left;

  &.error {
    background: var(--fill-error from global);
    box-shadow: 0 0.1rem 0.1rem 0 rgba(102, 0, 12, 0.3);
  }
}

.content {
  display: flex;
  align-items: center;
}

.text {
  padding-left: 1rem;
  line-height: 1.5;
}
