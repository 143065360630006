import * as styles from './listScreens.module.css'

import {
  type CallList,
  SortType,
  useGetCurrentUserQuery,
  useGetNotCallingListLazyQuery,
  ListType
} from '../../__generated__/gql-types'
import { Heading, Text } from '../../components/shared/typography'
import { useEffect, useState } from 'react'

import { Companies } from '~/src/components/call-list/Companies/Companies'
import { Container } from '../../components/shared/container/Container'
import { ListHeader } from '~/src/components/call-list/ListHeader/ListHeader'
import { useAuth } from '../../auth/hooks/useAuth'
import { useGlobalState } from '~/src/auth/hooks/useGlobal'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router'
import {
  ArchiveCallListModal,
  ExportCallListModal
} from '~/src/components/call-list/ExportCallListModal/ExportCallListModal'
import { type SCREEN_STATE } from './helpers/list.helper'

const testId = 'not-a-real-active-id/not-calling'

export const DncList = (): JSX.Element => {
  const { data: userData } = useGetCurrentUserQuery()
  const params = useParams()
  const navigate = useNavigate()
  const { id } = useAuth()
  const isReadOnly = id !== params?.id && params?.id !== testId

  // #region state
  const [sortValue, setSortValue] = useState<SortType>(
    userData?.currentUser?.call_list_sort_by ?? SortType.Alpha
  )
  const [, setScreenState] = useState<keyof typeof SCREEN_STATE>('DEFAULT')
  const { setGlobalState, defaultOpenContactCategory } = useGlobalState()
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false)
  const [exportDialogOpen, setExportDialogOpen] = useState(false)
  // #endregion state

  // #region gql
  const [
    getNotCallingList,
    { data: listData, loading: getNotCallingListLoading, called }
  ] = useGetNotCallingListLazyQuery({
    variables: {
      userId: typeof params?.id === 'string' ? params.id : ''
    }
  })

  const [contactSearchValue, setContactSearchValue] = useState<string>('')

  // #endregion gql

  // #region effects
  useEffect(() => {
    if (params?.id !== testId) {
      if (
        called &&
        !getNotCallingListLoading &&
        (listData == null || listData === undefined)
      )
        navigate('/call-list/not-found')
    }
  }, [called, getNotCallingListLoading, listData, navigate, params?.id])

  useEffect(() => {
    void getNotCallingList()
  }, [getNotCallingList])

  const data = listData?.getNotCallingList as CallList

  return (
    <>
      <Container>
        <ListHeader
          listType={ListType.NotCalling}
          sortValue={sortValue}
          setSortValue={setSortValue}
          setGlobalState={setGlobalState}
          listData={data}
          isReadOnly={isReadOnly}
          defaultOpenContactCategory={defaultOpenContactCategory}
          setContactSearchValue={setContactSearchValue}
          contactSearchValue={contactSearchValue}
          setExportDialogOpen={setExportDialogOpen}
          setArchiveDialogOpen={setArchiveDialogOpen}
        />
        {listData?.getNotCallingList === null && (
          <Text as='p' style='detail' color='grey-8'>
            An error occurred while loading your do not call list. Please try
            again
          </Text>
        )}
        {listData?.getNotCallingList?.companies != null &&
          listData.getNotCallingList.companies.length === 0 && (
            <div className={styles['company-container']}>
              <Heading as='h2' level='four'>
                It’s a little lonely here. No companies have been marked as Not
                Calling
              </Heading>
            </div>
          )}
        {listData?.getNotCallingList.companies &&
          listData?.getNotCallingList.companies.length > 0 && (
            <Companies
              listType={ListType.NotCalling}
              companiesData={listData?.getNotCallingList.companies}
              userId={listData?.getNotCallingList.user_ids.id}
              sortValue={sortValue}
              defaultOpenContactCategory={defaultOpenContactCategory}
              isReadOnly={true}
              contactSearchValue={contactSearchValue}
              isDncList={true}
              setScreenState={setScreenState}
            />
          )}
      </Container>
      <ArchiveCallListModal
        listType={ListType.NotCalling}
        isOpen={archiveDialogOpen}
        setIsOpen={setArchiveDialogOpen}
        callListUserId={listData?.getNotCallingList?.user_ids?.id as string}
      />
      <ExportCallListModal
        listType={ListType.NotCalling}
        isOpen={exportDialogOpen}
        setIsOpen={setExportDialogOpen}
      />
    </>
  )
}
