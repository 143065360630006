import { type FC } from 'react'
import { Button } from '../../../shared/button/Button'
import { Modal } from '../../../shared/modal/Modal'
import { Text } from '../../../shared/typography'

interface MoveContactDialogProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  onConfirm: () => void
  contactName: string
}

export const MoveContactModal: FC<MoveContactDialogProps> = ({
  isOpen,
  setIsOpen,
  onConfirm,
  contactName
}): JSX.Element => {
  return (
    <Modal
      key={`move-contact-${contactName}`}
      open={isOpen}
      onRequestClose={() => {
        setIsOpen(false)
      }}
      closeOnOutsideClick={false}
      title={'Confirm Moving Contact to Active Call List'}
      theme='alert'
    >
      <Text as='p' style='detail' color='black' weight='semibold'>
        {`Moving a contact to your active call list cannot be undone. Do you
      wish to proceed with moving “${contactName}” to your active call list?`}
      </Text>
      <br />
      <br />
      <Button
        as='button'
        type='button'
        style='primary'
        onClick={() => {
          onConfirm()
          setIsOpen(false)
        }}
        ariaLabel={'Confirm Move to Active List'}
      >
        Confirm
      </Button>

      <Button
        as='button'
        type='button'
        style='cancel'
        onClick={() => {
          setIsOpen(false)
        }}
      >
        Cancel
      </Button>
    </Modal>
  )
}
