import {
  useMemo,
  type FC,
  type Dispatch,
  type SetStateAction,
  useState
} from 'react'

/* types */
import {
  ListType,
  type Company,
  type MoveCompanyMutationFn
} from '../../../__generated__/gql-types'
import { type TextProps } from '../../shared/typography/Text'

/* components */
import { Text } from '../../shared/typography'
import { FlyoutIconButton } from '../FlyoutIconButton/FlyoutIconButton'
import { FlyoutListItem } from '../../shared/flyout/FlyoutListItem'
import { Button } from '../../shared/button/Button'
import { Icon } from '../../shared/icons/Icons'

/* helpers */
import { handleMoveCompany } from './helpers/handleMoveCompany'

/* styles */
import * as styles from './company.module.css'
import { type TogglePopover } from '../../table/Table'
import { Popover } from '../../shared/popover/Popover'
import { Tooltip } from '../../shared/tooltip/Tooltip'
import { SCREEN_STATE } from '~/src/screens/listScreens/helpers/list.helper'
import { FlyoutHelpText } from '../../shared/flyout/FlyoutHelpText'

interface CompanyTitleProps {
  data: Company
  isCompanyExpanded: boolean
  callListUserId: string
  isReadOnly: boolean
  listType: ListType
  setArchiveDialogOpen: Dispatch<SetStateAction<boolean>>
  setDuplicatesDialogOpen: Dispatch<SetStateAction<boolean>>
  setMoveCompanyDialogOpen: Dispatch<SetStateAction<boolean>>
  setCompanyDialogOpen: Dispatch<SetStateAction<boolean>>
  moveCompanyFn: MoveCompanyMutationFn
  onEdit: (company: Company) => void
  setScreenState: (state: keyof typeof SCREEN_STATE) => void
  setIsCompanyExpanded: Dispatch<SetStateAction<boolean>>
}

export const CompanyTitle: FC<CompanyTitleProps> = ({
  data,
  callListUserId,
  isReadOnly,
  isCompanyExpanded,
  onEdit,
  setArchiveDialogOpen,
  setDuplicatesDialogOpen,
  setMoveCompanyDialogOpen,
  setCompanyDialogOpen,
  moveCompanyFn,
  setIsCompanyExpanded,
  setScreenState,
  listType
}) => {
  const companyDetailsProps: Omit<TextProps, 'children'> = useMemo(
    () => ({
      color: 'dark-blue',
      weight: 'semibold',
      style: 'link',
      linkNewTab: true
    }),
    []
  )

  /* Toggle company expanded/collapsed */
  const handleCompanyToggle = (): void => {
    setIsCompanyExpanded((prevState) => !prevState)
  }

  const [showPopover, setShowPopover] = useState<boolean>(false)
  const [popoverMessage, setPopoverMessage] = useState<string>('')
  const [popoverIcon, setPopoverIcon] = useState<string>('')

  const togglePopover: TogglePopover = (icon, message, timeout = 2000) => {
    setPopoverMessage(message)
    setPopoverIcon(icon)
    setShowPopover(true)

    if (typeof timeout === 'number') {
      setTimeout(() => {
        setShowPopover(false)
      }, timeout)
    }
  }

  return (
    <div className={styles.company}>
      {showPopover && (
        <Popover popoverMessage={popoverMessage} popoverIcon={popoverIcon} />
      )}
      <div className={styles.title}>
        <>
          {!data?.bullhorn_id && (
            <Tooltip
              text='Company is not in Bullhorn, please add as new company or associate to an existing company to move it to your active list'
              tooltipId='tooltip-1'
              position='left'
              width='xl'
              id='incomplete-lead-tooltip'
              onClick={() => {}}
            >
              <Icon
                symbol='WarningAmberRoundedIcon'
                color='icon-warn'
                size='sm'
                space='right'
              />{' '}
            </Tooltip>
          )}

          {data?.linkedin ? (
            <div className={styles['link-flyout']}>
              <FlyoutIconButton
                id={`link-company-${data.id}-flyout`}
                label={`Links for ${data.name}`}
                position='left'
                buttonProps={{
                  style: 'link',
                  title: `${data.name}`,
                  children: (
                    <Text {...companyDetailsProps} as='span'>
                      {data?.name}
                    </Text>
                  )
                }}
                flyoutChildren={[
                  data?.bullhorn_id && (
                    <FlyoutListItem
                      key={`link-bullhorn-${data.id}`}
                      buttonText='Bullhorn'
                      iconSymbol='BusinessOutlinedIcon'
                      buttonProps={{
                        id: `link-bullhorn-${data.id}`,
                        linkNewTab: true,
                        as: 'a',
                        href: `https://cls42.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=ClientCorporation&id=${String(
                          data?.bullhorn_id
                        )}`
                      }}
                    />
                  ),
                  <FlyoutListItem
                    key={`link-linkedin-${data.id}`}
                    buttonText='LinkedIn'
                    iconSymbol='WorkOutlineOutlinedIcon'
                    buttonProps={{
                      id: `link-linkedin-${data.id}`,
                      as: 'a',
                      linkNewTab: true,
                      href: String(data.linkedin)
                    }}
                  />
                ]}
              />
            </div>
          ) : (
            <>
              {!data.bullhorn_id ? (
                <>
                  <Text
                    as='span'
                    color='dark-blue'
                    weight='semibold'
                    style={''}
                  >
                    {data?.name}
                  </Text>
                </>
              ) : (
                <Text
                  {...companyDetailsProps}
                  as='a'
                  href={`https://cls42.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=ClientCorporation&id=${String(
                    data?.bullhorn_id
                  )}`}
                >
                  {data?.name}
                </Text>
              )}
            </>
          )}

          {listType === ListType.Active &&
            data.duplicates > 0 &&
            !isReadOnly && (
              <div className={styles.duplicate}>
                <Button
                  as='button'
                  style='icon-only'
                  onClick={() => {
                    setDuplicatesDialogOpen(true)
                  }}
                  id={`show-duplicates-${data.id}`}
                >
                  <Icon
                    symbol='BusinessOutlinedIcon'
                    color='alku-red'
                    size='md'
                  />
                </Button>
              </div>
            )}

          {listType === ListType.NotCalling && (
            <div className={styles.edit}>
              <FlyoutIconButton
                id={`edit-company-${data.id}-flyout`}
                label={`More Options Menu for ${data.name}`}
                buttonProps={{ style: 'icon-only' }}
                iconProps={{ symbol: 'MoreVertOutlinedIcon' }}
                position='left'
                flyoutChildren={[
                  <FlyoutListItem
                    key={`more-dnc-${data.id}`}
                    buttonText='Move to Active'
                    iconSymbol='ExitToAppOutlinedIcon'
                    buttonProps={{ id: `dnc-company-${data.id}` }}
                    onClick={() => {
                      // TODO: should we open modal here too like we do for leads via setMoveCompanyDialogOpen
                      handleMoveCompany(
                        moveCompanyFn,
                        data,
                        ListType.Active,
                        callListUserId,
                        togglePopover
                      )
                    }}
                  />,
                  <FlyoutListItem
                    key={`more-archive-${data.id}`}
                    buttonText='Remove Company'
                    iconSymbol='DeleteOutlinedIcon'
                    buttonProps={{ id: `archive-company-${data.id}` }}
                    onClick={() => {
                      setArchiveDialogOpen(true)
                    }}
                  />
                ]}
              />
            </div>
          )}

          {listType === ListType.Active && (
            <>
              {!isReadOnly && (
                <div className={styles.edit}>
                  <FlyoutIconButton
                    id={`edit-company-${data.id}-flyout`}
                    label={`More Options Menu for ${data.name}`}
                    buttonProps={{ style: 'icon-only' }}
                    iconProps={{ symbol: 'MoreVertOutlinedIcon' }}
                    position='left'
                    flyoutChildren={[
                      <FlyoutListItem
                        key={`more-edit-${data.id}`}
                        buttonText='Edit Company'
                        iconSymbol='EditOutlinedIcon'
                        buttonProps={{ id: `edit-company-${data.id}` }}
                        onClick={() => {
                          onEdit(data)
                        }}
                      />,
                      <FlyoutListItem
                        key={`more-dnc-${data.id}`}
                        buttonText='Move to Not Calling'
                        iconSymbol='PhoneDisabledIcon'
                        buttonProps={{ id: `dnc-company-${data.id}` }}
                        onClick={() => {
                          handleMoveCompany(
                            moveCompanyFn,
                            data,
                            ListType.NotCalling,
                            callListUserId,
                            togglePopover
                          )
                        }}
                      />,
                      <FlyoutListItem
                        key={`more-archive-${data.id}`}
                        buttonText='Remove Company'
                        iconSymbol='DeleteOutlinedIcon'
                        buttonProps={{ id: `archive-company-${data.id}` }}
                        onClick={() => {
                          setArchiveDialogOpen(true)
                        }}
                      />
                    ]}
                  />
                </div>
              )}
            </>
          )}
          {listType === ListType.Leads && (
            <>
              {!isReadOnly && (
                <div className={styles.edit}>
                  <FlyoutIconButton
                    id={`edit-company-${data.id}-flyout`}
                    label={`More Options Menu for ${data.name}`}
                    buttonProps={{ style: 'icon-only' }}
                    iconProps={{ symbol: 'MoreVertOutlinedIcon' }}
                    position='left'
                    flyoutChildren={[
                      !data?.bullhorn_id && (
                        <FlyoutListItem
                          key={`add-company-${data.id}`}
                          buttonText='Add Company'
                          iconSymbol='BusinessOutlinedIcon'
                          buttonProps={{ id: 'open-add-company-dialog-button' }}
                          onClick={() => {
                            setScreenState(SCREEN_STATE.ADDING)
                            onEdit(data)
                            setCompanyDialogOpen(true)
                          }}
                        />
                      ),

                      <FlyoutListItem
                        key={`move-active-${data.id}`}
                        buttonText='Move to Active Call List'
                        iconSymbol='ControlPointIcon'
                        buttonProps={{ id: `move-active-button-${data.id}` }}
                        isDisabled={!data.bullhorn_id}
                        afterButton={
                          !data.bullhorn_id && (
                            <FlyoutHelpText message='Company needs to exist to move to active call list' />
                          )
                        }
                        onClick={() => {
                          setMoveCompanyDialogOpen(true)
                        }}
                      />,
                      <FlyoutListItem
                        key={`edit-company-${data.id}`}
                        buttonText='Edit Lead'
                        iconSymbol='EditOutlinedIcon'
                        buttonProps={{ id: `edit-company-button-${data.id}` }}
                        onClick={() => {
                          setScreenState(SCREEN_STATE.UPDATING)
                          onEdit(data)
                        }}
                      />,
                      <FlyoutListItem
                        key={`archive-company-${data.id}`}
                        buttonText='Remove Lead'
                        iconSymbol='DeleteOutlinedIcon'
                        buttonProps={{ id: `archive-company-${data.id}` }}
                        onClick={() => {
                          setArchiveDialogOpen(true)
                        }}
                      />
                    ]}
                  />
                </div>
              )}
            </>
          )}

          <div className={styles['contacts-count']}>
            <Button
              as='button'
              style='icon-only'
              id='company-expand-button'
              onClick={handleCompanyToggle}
              ariaLabel={`${isCompanyExpanded ? 'C' : 'Unc'}ollapse Company`}
            >
              <Text as='p' style='detail' color='dark-grey' weight='bold'>
                {data?.contacts?.length} Contact
                {data?.contacts?.length !== 1 ? 's' : ''}
              </Text>
              {!isCompanyExpanded ? (
                <Icon
                  symbol='ChevronRightOutlinedIcon'
                  color='alku-red'
                  size='lg'
                />
              ) : (
                <Icon
                  symbol='ExpandMoreOutlinedIcon'
                  color='alku-red'
                  size='lg'
                />
              )}
            </Button>
          </div>
        </>
      </div>
    </div>
  )
}
