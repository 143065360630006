import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { UIKit } from './screens/UiKit'
import { Header } from './components/header/Header'
import TagManager from 'react-gtm-module'
import { MyLists } from './screens/MyLists'
import { useAuth } from './auth/hooks/useAuth'
import { Alert } from './components/shared/alert/Alert'
import { useErrorHandler } from './auth/hooks/useErrorHandler'
import { Container } from './components/shared/container/Container'
import { ActiveList } from './screens/listScreens/ActiveList'
import { Directory } from './screens/Directory'
import { NotFound } from './screens/notFound/NotFound'
import { DncList } from './screens/listScreens/DncList'
import featureFlags from '~/src/config/config'
import { LeadsList } from './screens/listScreens/LeadsList'

export function App(): JSX.Element {
  const { bullhorn_id } = useAuth()

  const tagManagerArgs = {
    gtmId: 'GTM-TQLBVGH',
    dataLayer: {
      userId: bullhorn_id
    }
  }

  const { hasError, errorMessage } = useErrorHandler()

  TagManager.initialize(tagManagerArgs)

  return (
    <Router>
      <Header />
      <main>
        {hasError && (
          <Container>
            <Alert id='global-error' type='error'>
              {errorMessage}
            </Alert>
            <br />
          </Container>
        )}
        <Routes>
          <Route path='/directory' element={<Directory />}></Route>
          <Route path='/' element={<MyLists />}></Route>
          <Route path='/:id' element={<ActiveList />} />
          <Route path='/:id/not-calling' element={<DncList />} />
          <Route path='*' element={<NotFound />} />
          <Route path='/:id/leads' element={<LeadsList />} />
          {featureFlags.uiKit && (
            <Route path='/ui-kit' element={<UIKit />}></Route>
          )}
        </Routes>
      </main>
    </Router>
  )
}
