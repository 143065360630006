import { Icon } from '../icons/Icons'
import * as styles from './validationError.module.css'
import { type ReactNode } from 'react'
import classNames from 'classnames'

/**
 * ValidationError
 * @param {ReactNode} children - support for text and hyperlinks
 */
export const ValidationError = ({
  children
}: {
  children: ReactNode
}): JSX.Element => {
  return (
    <div className={classNames(styles.container, 'error')}>
      <div className={styles.content}>
        <Icon symbol='ErrorOutlineOutlinedIcon' color='alku-red' size='sm' />
        <div className={styles.text}>{children}</div>
      </div>
    </div>
  )
}
