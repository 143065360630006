import { useState } from 'react'
import { Button } from '../button/Button'
import { Icon } from '../icons/Icons'

interface ClipboardCopyProps {
  id: string
  text: string
  label: string
}

export const ClipboardCopy = ({
  id,
  text,
  label
}: ClipboardCopyProps): JSX.Element => {
  const [isCopied, setIsCopied] = useState(false)

  async function copyTextToClipboard(text: string): Promise<void> {
    await navigator.clipboard.writeText(text)
  }

  const handleCopyClick = (text: string): void => {
    copyTextToClipboard(text)
      .then(() => {
        setIsCopied(true)
        setTimeout(() => {
          setIsCopied(false)
        }, 1500)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <Button
      as='button'
      title='Copy to Clipboard'
      style='icon-only'
      ariaLabel={label}
      onClick={() => {
        handleCopyClick(text)
      }}
      id={`copy-text-${id}`}
    >
      <Icon
        symbol={
          isCopied ? 'CheckCircleOutlinedIcon' : 'ContentCopyOutlinedIcon'
        }
        color={isCopied ? 'text-green' : 'alku-red'}
        size='sm'
        space='center'
      />
    </Button>
  )
}
