import { type FC } from 'react'
import { Button } from '../../shared/button/Button'
import { Modal } from '../../shared/modal/Modal'
import { Text } from '../../shared/typography'
import * as styles from './MoveCompanyModal.module.css'

interface MoveCompanyDialogProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  onConfirm: () => void
  entityName: string
}

export const MoveCompanyModal: FC<MoveCompanyDialogProps> = ({
  isOpen,
  setIsOpen,
  onConfirm,
  entityName
}): JSX.Element => {
  return (
    <Modal
      open={isOpen}
      onRequestClose={() => {
        setIsOpen(false)
      }}
      closeOnOutsideClick={false}
      title='Confirm Move Lead to Active Call List'
      theme='alert'
    >
      <Text as='p' style='detail' color='black' weight='semibold'>
        {`Moving this company will remove the company and all related contacts for your leads list and add them to your active call list. Do you wish to proceed moving ${entityName}?`}
      </Text>
      <br />
      <br />
      <Button
        as='button'
        type='button'
        style='primary'
        onClick={() => {
          onConfirm()
          setIsOpen(false)
          // Todo add loading state and popover
        }}
        ariaLabel='Confirm'
      >
        Confirm
      </Button>

      <Button
        as='button'
        type='button'
        style='primary'
        onClick={() => {
          setIsOpen(false)
        }}
        className={styles.cancel}
      >
        Cancel
      </Button>
    </Modal>
  )
}
