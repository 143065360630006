import { Form, Formik, type FormikHelpers, type FormikProps } from 'formik'
import {
  useAddContactMutation,
  type Contact,
  type AddContactInput,
  type UpdateContactInput,
  useUpdateContactMutation,
  type AddContactMutation,
  type Company,
  GetActiveListSummaryDocument,
  useFindDuplicateContactsLazyQuery,
  DuplicateType
} from '../../../__generated__/gql-types'

import { Button } from '../../shared/button/Button'
import { Label, TextArea, TextInput } from '../../shared/forms/form-elements'
import * as styles from './contactForm.module.css'
import * as Yup from 'yup'
import { useEffect, useMemo, useRef, useState, type ChangeEvent } from 'react'
import { type TogglePopover } from '../../table/Table'
import { type StoreObject, type BaseMutationOptions } from '@apollo/client'
import { Alert } from '../../shared/alert/Alert'
import { stateData } from '../CompanyForm/constants/formData'
import { SelectDropdownField } from '../../shared/select/SelectDropdown'
import { ContactDuplicatesComponent } from '../ContactsDupesModal/ContactDuplicatesComponent'

interface ContactFormProps {
  handleContactModalClose: () => void
  data?: Contact
  companyObject: Company
  togglePopover: TogglePopover
  callListUserId: string
}

const FORM_STATE = {
  EDITING: 'EDITING',
  ADDING: 'ADDING'
} as const

export const ContactForm = ({
  data,
  handleContactModalClose,
  companyObject,
  togglePopover,
  callListUserId
}: ContactFormProps): JSX.Element => {
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const handleCloseModal = (): void => {
    handleContactModalClose()
    setShowDuplicates(false)
    setIsCheckingDupes(false)
    setHasSubmitted(false)
    setSubmitting(false)
  }

  const formState: keyof typeof FORM_STATE = (() => {
    return data !== undefined ? FORM_STATE.EDITING : FORM_STATE.ADDING
  })()

  const [showDuplicates, setShowDuplicates] = useState<boolean>(false)
  const [isCheckingDupes, setIsCheckingDupes] = useState<boolean>(false)

  const formRef =
    useRef<FormikProps<AddContactInput | UpdateContactInput>>(null)

  const [
    findDuplicates,
    { data: duplicates, loading: duplicatesLoading, error: duplicatesError }
  ] = useFindDuplicateContactsLazyQuery()

  const [submitting, setSubmitting] = useState(false)

  const amclPartialMatches = useMemo(() => {
    return duplicates?.findDuplicateContacts.amcl.filter(
      (contact) =>
        contact.type === DuplicateType.AmclSoftContact &&
        contact.id !== data?.id
    )
  }, [data?.id, duplicates])

  const amclHardMatches = useMemo(() => {
    return duplicates?.findDuplicateContacts.amcl.filter(
      (contact) =>
        contact.type === DuplicateType.AmclHardContact &&
        contact.id !== data?.id
    )
  }, [data?.id, duplicates])

  const bullhornMatches = useMemo(() => {
    return duplicates?.findDuplicateContacts.bullhorn
  }, [duplicates])

  const handleDupeCheck = async (
    values: AddContactInput | UpdateContactInput
  ): Promise<void> => {
    if (values) {
      await findDuplicates({
        variables: {
          company_id: companyObject.id,
          first_name: values.first_name as string,
          last_name: values.last_name as string,
          email: values.email
        },
        fetchPolicy: 'no-cache' // Avoid cached dupes gql queries
      })
    }
  }

  useEffect(() => {
    if (
      amclHardMatches !== undefined &&
      bullhornMatches !== undefined &&
      amclPartialMatches !== undefined
    ) {
      if (
        amclHardMatches.length > 0 ||
        bullhornMatches.length > 0 ||
        amclPartialMatches.length > 0
      ) {
        // if dupes found show them
        setShowDuplicates(true)
        setIsCheckingDupes(false)
      } else if (
        amclHardMatches.length === 0 &&
        bullhornMatches.length === 0 &&
        amclPartialMatches.length === 0 &&
        !hasSubmitted
      ) {
        // proceed with mutation
        setSubmitting(true)
        setIsCheckingDupes(false)
        setTimeout(() => {
          if (formRef.current && !hasSubmitted) formRef.current.handleSubmit()
        }, 200)
        setHasSubmitted(true)
      }
    }
  }, [
    isCheckingDupes,
    amclHardMatches,
    bullhornMatches,
    amclPartialMatches,
    hasSubmitted
  ])

  const initialValues = {
    first_name: data?.first_name ?? '',
    last_name: data?.last_name ?? '',
    title: data?.title ?? '',
    email: data?.email ?? '',
    address_state: data?.address_state ?? '',
    phone_direct: data?.phone_direct ?? '',
    phone_mobile: data?.phone_mobile ?? '',
    phone_other: data?.phone_other ?? '',
    notes: data?.notes ?? ''
  }

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .trim()
      .max(50)
      .required('Required')
      .label('First Name'),
    last_name: Yup.string()
      .trim()
      .max(50)
      .required('Required')
      .label('Last Name'),
    title: Yup.string().max(128).label('Title'),
    email: Yup.string().email().label('Email').trim(),
    address_state: Yup.string().label('Location').nullable().trim(),
    phone_direct: Yup.string().label('Direct Phone').nullable().max(20),
    phone_mobile: Yup.string().label('Mobile Phone').nullable().max(20),
    phone_other: Yup.string().label('Other Phone').nullable().max(20),
    notes: Yup.string().label('Notes')
  })

  const hasError = (error: string | undefined): boolean => {
    if (error === undefined || error === '') return false
    if (error.length > 0) return true
    return false
  }

  const triggerGtmEvent = (): void => {
    window.dataLayer.push({
      event:
        formState === FORM_STATE.ADDING ? 'ga-add-contact' : 'ga-edit-contact'
    })
  }

  const mutationOptions: Pick<BaseMutationOptions, 'onCompleted' | 'onError'> =
    {
      onCompleted: () => {
        togglePopover('success', 'Contact Added!')
        // TODO: This is causing the success message to not appear at all
        // I am not exactly sure why but it's almost like it's causing the
        // parent component to re-render and reset the local state
        // Best I could do atm is isolate so it only gets triggered after the mutation is complete
        handleCloseModal()
      },
      onError: (msg) => {
        console.log('Error adding contact:', msg)
      }
    }

  const [addContact, { error: addError }] = useAddContactMutation({
    ...mutationOptions,
    update: (cache, result) => {
      const rootKey = 'addContact' as keyof AddContactMutation

      if (!result?.data?.[rootKey]) return

      // newly created contact cache key. used for updating the calllist cache
      const contactCacheKey = cache.identify(
        result.data[rootKey] as StoreObject
      )

      // add new contact ref to other contact refs in the specific calllist company's cached `contacts` array
      cache.modify({
        id: cache.identify(companyObject),
        fields: {
          contacts(existingContactRefs) {
            return [...existingContactRefs, { __ref: contactCacheKey }]
          }
        }
      })
    }
  })

  const [updateContact] = useUpdateContactMutation()

  interface FormGroupType {
    isSubmitting: boolean
    errors: string | undefined
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void
    touched: boolean | undefined
    id: string
    name: string
    placeholder: string
    label: string
    value: string
    required?: boolean
  }

  const FormGroup = ({
    isSubmitting,
    errors,
    handleChange,
    touched,
    id,
    name,
    placeholder,
    label,
    value,
    required = false
  }: FormGroupType): JSX.Element => {
    return (
      <div className={styles.formGroup}>
        <Label inputId={id} inputIsRequired={required}>
          {label}
        </Label>
        <TextInput
          id={id}
          name={name}
          required={required}
          placeholder={placeholder}
          value={value}
          hasError={isSubmitting && hasError(errors)}
          handleChange={handleChange}
          touched={touched}
        />
      </div>
    )
  }

  const handleFormSubmit = (
    values: AddContactInput | UpdateContactInput,
    actions: FormikHelpers<AddContactInput | UpdateContactInput>
  ): void => {
    const contact: Omit<UpdateContactInput, 'preferred_phone' | 'status'> = {
      first_name: values.first_name?.trim(),
      last_name: values.last_name?.trim(),
      title: values.title?.trim(),
      email: values.email?.trim(),
      address_state: values.address_state?.trim(),
      phone_direct: values.phone_direct?.trim(),
      phone_mobile: values.phone_mobile?.trim(),
      phone_other: values.phone_other?.trim(),
      notes: values.notes?.trim()
    }
    actions.setSubmitting(false)

    if (!isCheckingDupes) {
      triggerGtmEvent()
      if (data !== undefined) {
        // updateContact
        togglePopover('loading', 'Updating Contact...')
        void updateContact({
          variables: {
            id: data.id,
            contact
          },
          onCompleted: () => {
            togglePopover('success', 'Contact Updated!')
            handleCloseModal()
          },
          refetchQueries: [
            {
              query: GetActiveListSummaryDocument,
              variables: { userId: callListUserId }
            }
          ]
        })
      } else {
        // addContact
        togglePopover('loading', 'Adding Contact...')
        void addContact({
          variables: {
            input: {
              company_id: companyObject.id,
              ...contact,
              imported_from: 'amclManual'
            } as unknown as AddContactInput // TODO: idk about this type..
          },
          onCompleted: () => {
            togglePopover('success', 'Contact Added!')
            // TODO: This is causing the success message to not appear at all
            // I am not exactly sure why but it's almost like it's causing the
            // parent component to re-render and reset the local state
            // Best I could do atm is isolate so it only gets triggered after the mutation is complete
            handleCloseModal()
          },
          refetchQueries: [
            {
              query: GetActiveListSummaryDocument,
              variables: { userId: callListUserId }
            }
          ]
        })
      }
    }
  }

  return (
    <div>
      <div className={styles.formContainer}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
          innerRef={formRef}
          enableReinitialize
        >
          {({ errors, isSubmitting, touched, handleChange, values }) => (
            <>
              {addError?.message && (
                <div className={styles.alert}>
                  <Alert id='add-contact-error' type='error'>
                    {addError?.message}
                  </Alert>
                </div>
              )}
              <Form id='add-edit-contact' className={styles.form}>
                {!showDuplicates ? (
                  <>
                    <FormGroup
                      id='contact_first_name'
                      name='first_name'
                      label='First Name'
                      required
                      placeholder=''
                      isSubmitting={isSubmitting}
                      touched={touched.first_name}
                      handleChange={handleChange}
                      value={values.first_name ?? ''}
                      errors={errors.first_name ?? ''}
                    />
                    <FormGroup
                      id='contact_last_name'
                      name='last_name'
                      label='Last Name'
                      required
                      placeholder=''
                      isSubmitting={isSubmitting}
                      touched={touched.last_name}
                      handleChange={handleChange}
                      value={values.last_name ?? ''}
                      errors={errors.last_name ?? ''}
                    />
                    <FormGroup
                      id='contact_title'
                      name='title'
                      label='Title'
                      placeholder=''
                      isSubmitting={isSubmitting}
                      touched={touched.title}
                      handleChange={handleChange}
                      value={values.title ?? ''}
                      errors={errors.title}
                    />
                    <FormGroup
                      id='phone_direct'
                      name='phone_direct'
                      label='Direct Phone'
                      placeholder=''
                      isSubmitting={isSubmitting}
                      touched={touched.phone_direct}
                      handleChange={handleChange}
                      value={values.phone_direct ?? ''}
                      errors={errors.phone_direct}
                    />
                    <FormGroup
                      id='phone_mobile'
                      name='phone_mobile'
                      label='Mobile Phone'
                      placeholder=''
                      isSubmitting={isSubmitting}
                      touched={touched.phone_mobile}
                      handleChange={handleChange}
                      value={values.phone_mobile ?? ''}
                      errors={errors.phone_mobile}
                    />
                    <FormGroup
                      id='phone_other'
                      name='phone_other'
                      label='Other Phone'
                      placeholder=''
                      isSubmitting={isSubmitting}
                      touched={touched.phone_other}
                      handleChange={handleChange}
                      value={values.phone_other ?? ''}
                      errors={errors.phone_other}
                    />
                    <div className={styles.formGroup}>
                      <SelectDropdownField
                        id='contact_location'
                        name='address_state'
                        placeholder='Select State:'
                        label='Location'
                        options={stateData}
                        value={stateData.find(
                          (s) => s.value === values?.address_state
                        )}
                        hasError={hasError(errors.address_state)}
                        touched={touched.address_state}
                      />
                    </div>
                    <FormGroup
                      id='contact_email'
                      name='email'
                      label='Email'
                      placeholder=''
                      isSubmitting={isSubmitting}
                      touched={touched.email}
                      handleChange={handleChange}
                      value={values.email ?? ''}
                      errors={errors.email}
                    />
                    <div className={styles.formGroup}>
                      <TextArea
                        id='company_notes'
                        name='notes'
                        value={values.notes ?? ''}
                        hasError={hasError(errors.notes)}
                        handleChange={handleChange}
                        label='Contact Notes'
                      />
                    </div>
                    <Button
                      id='submit-contact'
                      as='button'
                      type='submit'
                      style='primary'
                      ariaLabel='Submit'
                      disabled={duplicatesLoading || submitting}
                      onClick={async () => {
                        if (!Object.keys(errors).length) {
                          setIsCheckingDupes(true)
                          await handleDupeCheck(values)
                        } else {
                          setIsCheckingDupes(false)
                        }
                      }}
                    >
                      {formState === FORM_STATE.ADDING ? 'Add' : 'Edit'} Contact
                    </Button>
                  </>
                ) : (
                  <>
                    <ContactDuplicatesComponent
                      amclHardMatches={amclHardMatches ?? []}
                      amclPartialMatches={amclPartialMatches ?? []}
                      bullhornMatches={bullhornMatches ?? []}
                      contactObject={values}
                      companyObject={companyObject}
                      loading={duplicatesLoading}
                      error={duplicatesError}
                      type='add'
                    />
                    <Button
                      id='submit-contact'
                      as='button'
                      type='submit'
                      style='primary'
                      ariaLabel='Submit'
                      onClick={() => {
                        setIsCheckingDupes(false)
                      }}
                      disabled={duplicatesLoading || submitting}
                    >
                      {formState === FORM_STATE.ADDING ? 'Add' : 'Edit'} Contact
                      Anyway
                    </Button>
                    {!duplicatesLoading && (
                      <Button
                        as='button'
                        type='button'
                        style='cancel'
                        onClick={() => {
                          handleCloseModal()
                        }}
                        disabled={duplicatesLoading || submitting}
                      >
                        Don’t {formState === FORM_STATE.ADDING ? 'Add' : 'Edit'}{' '}
                        Contact
                      </Button>
                    )}
                  </>
                )}
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  )
}
