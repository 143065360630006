import { type FC } from 'react'
import {
  type Company,
  type CallList,
  ContactStatus,
  ListType
} from '../../../__generated__/gql-types'
import { Text } from '../../shared/typography'
import * as styles from './CallListHeading.module.css'

interface CallListHeadingSummaryProps {
  summaryType?: ListType
  callList: CallList | undefined | null
  isReadOnly: boolean
}

export const CallListHeadingSummary: FC<CallListHeadingSummaryProps> = ({
  summaryType = ListType.Active,
  callList,
  isReadOnly
}) => {
  if (!callList) return <>{'...'}</>

  if (callList === null) {
    return (
      <Text style='detail'>
        {isReadOnly && <div>Unknown</div>}
        <span className={styles.status}>Companies:</span>
        <Text as='span' style='detail' weight='semibold'>
          0
        </Text>
        <span className={styles.status}>Contacts:</span>
        <Text as='span' style='detail' weight='semibold'>
          0
        </Text>
        <span className={styles.status}>Active:</span>
        <Text as='span' style='detail' weight='semibold'>
          0
        </Text>
        <span className={styles.status}>DBA:</span>
        <Text as='span' style='detail' weight='semibold'>
          0
        </Text>
        <span className={styles.status}>Disqualified:</span>
        <Text as='span' style='detail' weight='semibold'>
          0
        </Text>
      </Text>
    )
  }
  const companies = callList.companies

  const companiesTotal = companies?.length
  const contactsTotals = getContactsTotals(companies)

  return (
    <Text
      as='p'
      style='detail'
      color='grey-8'
      key={`aggregate${1}`}
      className={styles['summary-string']}
    >
      {isReadOnly && (
        <Text as='span' style='detail'>
          {callList.summary?.user_division ?? 'Unknown'}
        </Text>
      )}
      <span className={styles.status}>Companies:</span>
      <Text as='span' style='detail' weight='semibold'>
        {companiesTotal ?? 0}
      </Text>
      <span className={styles.status}>Contacts:</span>
      <Text as='span' style='detail' weight='semibold'>
        {contactsTotals.contactsTotal ?? 0}
      </Text>
      {summaryType !== ListType.Leads && (
        <>
          <span className={styles.status}>Active:</span>
          <Text as='span' style='detail' weight='semibold'>
            {contactsTotals.activeTotal ?? 0}
          </Text>
          <span className={styles.status}>DBA:</span>
          <Text as='span' style='detail' weight='semibold'>
            {contactsTotals.dbasTotal ?? 0}
          </Text>
          <span className={styles.status}>Disqualified:</span>
          <Text as='span' style='detail' weight='semibold'>
            {contactsTotals.disqualifiedTotal ?? 0}
          </Text>
        </>
      )}
    </Text>
  )
}

interface ContactDBATotals {
  contactsTotal: number
  dbasTotal: number
  activeTotal: number
  disqualifiedTotal: number
}
const getContactsTotals = (companies: Company[]): ContactDBATotals => {
  let contactsTotal: number = 0
  let dbasTotal: number = 0
  let activeTotal: number = 0
  let disqualifiedTotal: number = 0

  companies?.forEach((company: Company) => {
    company?.contacts != null && (contactsTotal += company?.contacts?.length)
    company?.contacts != null &&
      (dbasTotal += company?.contacts?.filter(
        (contact) =>
          contact?.status === ContactStatus.Dba ||
          contact?.status === ContactStatus.Requalified
      ).length)
    company?.contacts != null &&
      (activeTotal += company?.contacts?.filter(
        (contact) => contact?.status === ContactStatus.Active
      ).length)
    company?.contacts != null &&
      (disqualifiedTotal += company?.contacts?.filter(
        (contact) => contact?.status === ContactStatus.Disqualified
      ).length)
  })
  return { contactsTotal, activeTotal, dbasTotal, disqualifiedTotal }
}
