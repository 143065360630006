/* Sizes */
.xs {
  font-size: 0.9rem;
}

.sm {
  font-size: 1.3rem;
}

.md {
  font-size: 1.6rem;
}

.lg {
  font-size: 2rem;
}

.xl {
  font-size: 2.4rem;
}

.xxxl {
  font-size: 6.8rem;
}

/* Space - add as needed */

.none {
  padding: 0;
}

.right {
  margin-right: 1.2rem;
}

.left {
  margin-left: 1.2rem;
}

.center {
  padding: 0 0.8rem;
}

.animated {
  padding: 0.8rem;
}

/* rotate */
.open {
  transform-origin: center;
  transform: translate(50%, 0%) rotate(-180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  display: inline-block;
}

.no-shrink {
  flex-shrink: 0;
  line-height: 1;
}
