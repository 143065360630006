import { useEffect, type FC } from 'react'
import { Modal } from '../../shared/modal/Modal'
import {
  type Contact,
  useGetDuplicateContactsLazyQuery,
  DuplicateType,
  type Company
} from '~/src/__generated__/gql-types'
import { ContactDuplicatesComponent } from './ContactDuplicatesComponent'

interface ContactsDupesModalProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  yourContactData: Contact
  yourCompanyData: Company
}

export const ContactsDupesModal: FC<ContactsDupesModalProps> = ({
  isOpen,
  setIsOpen,
  yourContactData,
  yourCompanyData
}): JSX.Element => {
  const [getDuplicatesContacts, { data: duplicates, loading, error }] =
    useGetDuplicateContactsLazyQuery()

  useEffect(() => {
    if (isOpen) {
      void getDuplicatesContacts({
        variables: {
          contactId: yourContactData.id
        },
        fetchPolicy: 'no-cache' // Avoid cached dupes gql query
      })
    }
  }, [getDuplicatesContacts, isOpen, yourContactData.id])

  const amclPartialMatches =
    duplicates?.getDuplicateContacts.amcl.filter(
      (contact) =>
        contact.type === DuplicateType.AmclSoftContact &&
        contact.id !== yourContactData.id // Exclude the current contact
    ) ?? []

  const amclHardMatches =
    duplicates?.getDuplicateContacts.amcl.filter(
      (contact) =>
        contact.type === DuplicateType.AmclHardContact &&
        contact.id !== yourContactData.id // Exclude the current contact
    ) ?? []

  const bullhornMatches = duplicates?.getDuplicateContacts.bullhorn ?? []

  return (
    <Modal
      open={isOpen}
      onRequestClose={() => {
        setIsOpen(false)
      }}
      title='Duplicate Found!'
      closeOnOutsideClick={false}
      theme='error'
    >
      <>
        {loading && <div>Loading...</div>}
        {error && <div>Error: {error.message}</div>}
        {!loading && !duplicates && <div>No duplicates found</div>}
        {!loading && duplicates && (
          <ContactDuplicatesComponent
            amclHardMatches={amclHardMatches}
            amclPartialMatches={amclPartialMatches}
            bullhornMatches={bullhornMatches}
            contactObject={yourContactData}
            companyObject={yourCompanyData}
            loading={loading}
            error={error}
            type='view'
          />
        )}
      </>
    </Modal>
  )
}
