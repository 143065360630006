import {
  type UpdateCompanyInput,
  type Company,
  type UpdateCompanyMutationFn,
  type MoveCompanyMutationFn,
  type MoveLeadToActiveMutationFn,
  GetActiveListSummaryDocument,
  GetLeadsListSummaryDocument,
  GetNotCallingListSummaryDocument,
  type UpdateCompanyMutation,
  GetActiveListDocument,
  GetNotCallingListDocument,
  ListType,
  type GetActiveListQuery,
  type GetNotCallingListQuery,
  GetLeadsListDocument,
  type GetLeadsListQuery
} from '../../../../__generated__/gql-types'
import { type StoreObject } from '@apollo/client/cache'
import { type TogglePopover } from '~/src/components/table/Table'

/* Function to archive a company */
export const handleArchiveCompany = (
  updateCompanyFn: UpdateCompanyMutationFn,
  companyObject: Company,
  callListUserId: string,
  togglePopover: TogglePopover
): void => {
  const input: UpdateCompanyInput = { is_archived: true }
  void updateCompanyFn({
    variables: { id: companyObject.id, input },
    onCompleted: () => {
      togglePopover('success', 'Company successfully removed')
    },
    onError: (error) => {
      togglePopover('error', 'Error archiving company')
      console.error('Error executing mutation:', error.message)
    },
    update: (cache, result) => {
      const rootKey = 'updateCompany' as keyof UpdateCompanyMutation

      if (!result?.data?.[rootKey]) return

      const companyCacheKey = cache.identify(
        result.data[rootKey] as StoreObject
      )

      cache.evict({ id: companyCacheKey })
    },
    refetchQueries: [
      {
        query: GetActiveListSummaryDocument,
        variables: { userId: callListUserId }
      },
      {
        query: GetNotCallingListSummaryDocument,
        variables: { userId: callListUserId }
      },
      {
        query: GetNotCallingListDocument,
        variables: { userId: callListUserId }
      },
      {
        query: GetActiveListDocument,
        variables: { userId: callListUserId }
      }
    ],
    awaitRefetchQueries: true
  })
}

/* Function to move a company to a different list (between active and not calling) */
export const handleMoveCompany = (
  moveCompanyFn: MoveCompanyMutationFn,
  companyObject: Company,
  listType: ListType,
  callListUserId: string,
  togglePopover: TogglePopover
): void => {
  void moveCompanyFn({
    variables: { companyId: companyObject.id, listType },
    onCompleted: () => {
      togglePopover('success', 'Company moved successfully')
    },
    onError: (error) => {
      togglePopover('error', 'Error moving company')
      console.error('Error executing mutation:', error.message)
    },
    update: (cache, result) => {
      const activeResults = cache.readQuery({
        query: GetActiveListDocument,
        variables: {
          userId: callListUserId
        }
      }) as GetActiveListQuery

      const notCallingResults = cache.readQuery({
        query: GetNotCallingListDocument,
        variables: {
          userId: callListUserId
        }
      }) as GetNotCallingListQuery

      if (
        activeResults?.getActiveList ||
        notCallingResults?.getNotCallingList
      ) {
        // NOTE: the cache identify param might be undefined if the other list doesn't exist in the cache
        // Ex warning message: Cannot read properties of undefined (reading '__typename')
        const toCallListCacheKey = cache.identify(
          listType === ListType.Active
            ? activeResults?.getActiveList
            : notCallingResults?.getNotCallingList
        )

        const fromCallListCacheKey = cache.identify(
          listType === ListType.Active
            ? notCallingResults?.getNotCallingList
            : activeResults?.getActiveList
        )

        const companyData = result?.data?.moveCompany
        if (companyData) {
          cache.modify({
            id: fromCallListCacheKey,
            fields: {
              companies(existingCompanyRefs) {
                return [
                  ...existingCompanyRefs.filter(
                    (x: any) => x.__ref !== cache.identify(companyData)
                  )
                ]
              }
            }
          })
        }

        // NOTE: we could also write to the other call list cache if we wanted to avoid the eviction
        if (toCallListCacheKey)
          cache.evict({ id: toCallListCacheKey, broadcast: false })
      }
    },
    refetchQueries: [
      {
        query: GetActiveListSummaryDocument,
        variables: { userId: callListUserId }
      },
      {
        query: GetNotCallingListSummaryDocument,
        variables: { userId: callListUserId }
      }
    ]
  })
}

/* Function to move a lead to the active list */
export const handleLeadMoveToActive = (
  moveToActiveFn: MoveLeadToActiveMutationFn,
  companyObject: Company,
  callListUserId: string,
  togglePopover: TogglePopover
): void => {
  void moveToActiveFn({
    variables: { companyId: companyObject.id },
    onCompleted: () => {
      togglePopover('success', 'Lead moved successfully')
    },
    onError: (error) => {
      togglePopover('error', error.message)
    },
    update: (cache, result) => {
      const activeResults = cache.readQuery({
        query: GetActiveListDocument,
        variables: {
          userId: callListUserId
        }
      }) as GetActiveListQuery

      const leadsResults = cache.readQuery({
        query: GetLeadsListDocument,
        variables: {
          userId: callListUserId
        }
      }) as GetLeadsListQuery

      // NOTE: the cache identify param might be undefined if the other list doesn't exist in the cache
      // Ex warning message: Cannot read properties of undefined (reading '__typename')
      const toCallListCacheKey = cache.identify(activeResults?.getActiveList)
      const fromLeadsListCacheKey = cache.identify(leadsResults?.getLeadsList)

      const companyData = result?.data?.moveLeadToActive

      if (companyData && fromLeadsListCacheKey) {
        // filters out the company from the leads list & modifies cache object
        cache.modify({
          id: fromLeadsListCacheKey,
          fields: {
            companies(existingCompanyRefs) {
              return [
                ...existingCompanyRefs.filter(
                  (x: any) => x.__ref !== cache.identify(companyData)
                )
              ]
            }
          }
        })
      }
      if (toCallListCacheKey)
        cache.evict({ id: toCallListCacheKey, broadcast: false })
    },
    refetchQueries: [
      {
        query: GetActiveListSummaryDocument,
        variables: { userId: callListUserId }
      },
      {
        query: GetLeadsListSummaryDocument,
        variables: { userId: callListUserId }
      },
      {
        query: GetActiveListDocument,
        variables: { userId: callListUserId }
      }
    ],
    awaitRefetchQueries: true
  })
}
